import React from 'react'
import "./Title.css"

const Title = ({ title, color }) => {
    return (
        <h1 className='main-title' data-aos="fade-down" data-aos-duration="1500" style={{ color: `${color}`}}>
            {title}
        </h1>
    )
}

export default Title

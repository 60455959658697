import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Row, Col, Container } from 'react-bootstrap';
import AOS from 'aos';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'aos/dist/aos.css';
import { AiBtn, ErrorComponent } from './Dashboard/Components';
import { SideBar, NavPages } from './Dashboard/Layouts';
import {
  Dashboard, Search, Amendments, Reminders, RegulationDetails, Notes, Regulation,
  ReportsPage, ReportsDetails, ProfilePage, ChatBot
} from './Dashboard/Pages';
import { Login, Register, CheckEmail, EmailVerified, ResetPassword } from './Dashboard/Auth';
import favIcon from "./Website/Assets/images/svg/logo-dark.svg";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "./Dashboard/assets/images/svg/logo.svg";
import { toast } from 'react-toastify';

// Website imports 
import { Home, Privacy, Pricing, PaymentPage, AboutPage, Contact, OurTeam, BookDemo, Trail } from "./Website/Pages";
import { MainNavbar, Footer } from "./Website/Layouts";
import { ScrollTop } from './Website/Components';

function DashboardLayout({ url, children, dashboardData, selectedFilters, setSelectedFilters, searchQuery, setSearchQuery,regulationSearchQuery,setRegulationSearchQuery, reportObject, callChangeReportFilters, loading, notificationsData, language, onChangeLang , setLanguage, translations}) {
  const location = useLocation();
  const shouldRenderSidebar = location.pathname !== '/dashboard/profile';
  const isProfilePath = location.pathname === '/dashboard/profile';

  return (
    <Row className={` ${isProfilePath ? 'profile-page' : ''}`}>
      {shouldRenderSidebar && (
        <Col xl={2} xs={2} className="sidebar-container">
          <SideBar
            url={url}
            data={dashboardData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            callChangeReportFilters={callChangeReportFilters}
            reportObject={reportObject}
            loading={loading}
            translations={translations}
          />
        </Col>
      )}
      <Col xl={10} className={` ${isProfilePath ? ' container profile-container' : 'content-container'}`}>
        <NavPages url={url} notificationsData={notificationsData}  setSearchQuery={setSearchQuery} searchQuery={searchQuery} language={language} onChangeLang={onChangeLang} setLanguage={setLanguage} translations={translations}/>
        <div className='ai-btn-container'>
          <AiBtn />
        </div>
        <Container className="content">
          {children}
        </Container>
      </Col>
    </Row>
  );
}

function WesiteLayout({ url, children, loading, translations, language, setLanguage }) {
  const location = useLocation();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  if (initialLoading || loading) {
    return (
      <div className='loading'>
       <svg className="star" width="128" height="128" viewBox="0 0 128 128">
      <defs>
      <filter id="star-glow">
      <feGaussianBlur result="coloredBlur" stdDeviation="1.5"></feGaussianBlur>
      <feMerge>
      <feMergeNode in="coloredBlur"></feMergeNode>
      <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
      </filter>
      <linearGradient id="star-grad" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#000" />
      <stop offset="100%" stop-color="#fff" />
      </linearGradient>
      <mask id="star-mask">
      <rect x="0" y="0" width="128" height="128" fill="url(#star-grad)" />
      </mask>
      </defs>
      <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
      <g stroke="hsla(223,90%,50%,0.2)">
      <polygon points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"/>
      <polygon points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"/>
      <polygon points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"/>
      <polygon points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"/>
      </g>
      <g filter="url(#star-glow)">
      <g stroke="#2DB0D8">
      <polygon className="star__stroke" stroke-dasharray="31 93" points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"/>
      <polygon className="star__stroke star__stroke--2" stroke-dasharray="62 186" points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"/>
      <polygon className="star__stroke star__stroke--3" stroke-dasharray="93 279" points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"/>
      <polygon className="star__stroke star__stroke--4" stroke-dasharray="124 372" points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"/>
      </g>
      <g stroke="#2DB0D8" mask="url(#star-mask)">
      <polygon className="star__stroke" stroke-dasharray="31 93" points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"/>
      <polygon className="star__stroke star__stroke--2" stroke-dasharray="62 186" points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"/>
      <polygon className="star__stroke star__stroke--3" stroke-dasharray="93 279" points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"/>
      <polygon className="star__stroke star__stroke--4" stroke-dasharray="124 372" points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"/>
      </g>
      </g>
      </g>
      </svg>
      </div>
    );
  }

  return (
    <>
      <MainNavbar url={url} translations={translations} language={language}  setLanguage={setLanguage}/>
      {children}
      <Footer translations={translations} />
    </>
  );
}

function App() {
  const url = 'https://dashboard.polarisai.ca';
  const [data, setData] = useState([]);
  const [verifyData, setVerifyData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [pageTitle, setPageTile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [regulationSearchQuery, setRegulationSearchQuery] = useState('');
  const [reportObject, setReportObject] = useState({});
  const [callChangeReportFilters, setCallChangeReportFilters] = useState(async () => { });
  const [notificationsData, setNotificationsData] = useState([]);
  const [language, setLanguage] = useState(Cookies.get('preferred_language')??"en"); // Default language is English
  const [translations, setTranslations] = useState(localStorage.getItem('translations')?JSON.parse(localStorage.getItem('translations')):{});

  const pageUrl = window.location.pathname;
  let pageName;

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);



  const fetchTranslations = async (lang) => {
    try {
      const response = await fetch(`${url}/api/method/projectx.www.polaris_api.get_translation_file?lang=${lang}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
          },
        }
      );
      if (!response.ok) {
        toast.error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setTranslations(data);
      localStorage.setItem('translations', JSON.stringify(data));
      window.location.reload();
      
    } catch (error) {
      toast.error(`'Error fetching translations: ${error}`);
    }
  };


  const changeLang = async (lang) => {
    try {
      const response = await axios.post(`${url}/api/method/projectx.www.polaris_api.changeLanguage?lang=${lang}`);
      console.log(response)
      if (response.data && response.data.message === true){
        Cookies.set('preferred_language', lang);
        fetchTranslations(lang);
      }
      else{
        toast.error('Failed to change language');
      }

    } catch (error) {
      toast.error('Error change lang:', error);
    }
  };

  useEffect(() => {
    console.log(language)
    var lang = Cookies.get('preferred_language');
    console.log(lang)
    if (lang != language) {
      changeLang(language);
    }
  }, [language])



  useEffect(() => {
    const faviconLink = document.querySelector('link[rel="shortcut icon"]');
    if (faviconLink) {
      faviconLink.href = favIcon;
    } else {
      const newFaviconLink = document.createElement('link');
      newFaviconLink.rel = 'shortcut icon';
      newFaviconLink.href = favIcon;
      document.head.appendChild(newFaviconLink);
    }
  }, []);

  // const clearCookies = () => {
  //   const cookies = document.cookie.split(";");
  //   console.log(cookies)
  //   for (let cookie of cookies) {
  //     const eqPos = cookie.indexOf("=");
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  //   }
  //   localStorage.clear();
  // };

  const clearCookies = (clearLang = false) => {
    const cookies = document.cookie.split(";");
    console.log(cookies);
    
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
      
      // Skip clearing the 'preferred_language' cookie if clearLang is false
      if (name === 'preferred_language' && !clearLang) {
        continue;
      }
      //todo if cookie is expired
      // localStorage.clear();



      // Clear the cookie by setting its expiration date in the past
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
    
  };


  const fetchWebsiteData = async (pageName) => {
    setLoading(true);
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
    var headers = { 'Content-Type': 'application/json', };

    if (apiKey == null && apiSecret == null) {
      clearCookies();
    } else {
      headers["Authorization"] = `token ${apiKey}:${apiSecret}`;
    }

    try {
      const response = await axios.get(
        `${url}/api/method/projectx.projectx.doctype.pages.pages.page?name=${pageName}`,
        { headers: headers }
      );

      if (response?.data?.result?.is_active === false) {
        clearCookies();
      }
      setData(response?.data?.result?.content?.templates || []);
      setPageTile(response?.data?.result?.page_title || "");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!window.location.pathname.startsWith('/dashboard') ) {
  //     if ((pageUrl.lastIndexOf("/") === -1) || (pageUrl.lastIndexOf("/") === pageUrl.length - 1)) {
  //       pageName = "home";
  //     } else if (window.location.pathname.startsWith('/resetpassword')){
  //         pageName="resetpassword";
  //     } else {
  //       pageName = pageUrl.substr(pageUrl.lastIndexOf("/") + 1);
  //     }
  //     fetchWebsiteData(pageName);
  //   }
  // }, [pageUrl]);

  const getPageNameFromUrl = () => {
    const pathArray = window.location.pathname.split('/');
    return pathArray[pathArray.length - 1];
  };

  const fetchDashboardData = async (pagename, regulationName = null) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchQuery = urlParams.get('search');


    setDashboardLoading(true);
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
  
    if (!apiKey || !apiSecret) {
      clearCookies();
      window.location.href = '/login';
      return;
    }
  
    let apiUrl = `${url}/api/method/projectx.projectx.doctype.pages.pages.page?name=${pagename}`;

    // if (regulationSearchQuery) {
    //   apiUrl += `&search=${regulationSearchQuery}`;
    // }
    if (searchQuery) {
      apiUrl += `&search=${searchQuery}`;
      setSearchQuery(searchQuery)
    }

    if (regulationName) {
      apiUrl += `&regulation=${regulationName}`;
    }
  
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
  
      if (response?.data?.result?.is_active) {
        setDashboardData(response?.data?.result?.content?.templates || []);
        setNotificationsData(response?.data?.result?.notifications?.notification_logs || []);
        setTimeout(() => {
          setDashboardLoading(false);
        }, 3000);
      } else {
        setDashboardLoading(false);
        clearCookies();
        window.location.href = '/login';
      }
      setPageTile(response?.data?.result?.page_title || "");
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setDashboardLoading(false);
    }
  };
  
  useEffect(() => {
    // Check if the pathname starts with '/dashboard'
    if (window.location.pathname.startsWith('/dashboard') ) {
      
      let pagename = getPageNameFromUrl();
  
      if (window.location.pathname.startsWith('/dashboard/reports/')) {
        pagename = "reports";
      }
  
      const isRegulationDetailsPage = /^\/dashboard\/regulation\/[^\/]+$/.test(window.location.pathname);
      const isChatbotPage = window.location.pathname.endsWith('/chatbot');
      const isAmendmentsDetailsPage = /^\/dashboard\/amendments\/[^\/]+$/.test(window.location.pathname);
  
      if (!isChatbotPage) {
        if (isRegulationDetailsPage) {
          // Extract the regulation name before the ?
          const regulationName = window.location.pathname.split('/').pop().split('?')[0];
          fetchDashboardData('regulation details', regulationName);
        } else if (isAmendmentsDetailsPage) {
          const regulationName = window.location.pathname.split('/').pop();
          fetchDashboardData('amendments', regulationName);
        } else {
          fetchDashboardData(pagename);
        }
      }
    } else if (!window.location.pathname.startsWith('/resetpassword')) {
      if ((pageUrl.lastIndexOf("/") === -1) || (pageUrl.lastIndexOf("/") === pageUrl.length - 1)) {
        pageName = "home";
      } else {
        pageName = pageUrl.substr(pageUrl.lastIndexOf("/") + 1);
      }
      fetchWebsiteData(pageName);
    }
  }, [pageUrl]);
  
  
  
  


  useEffect(() => {
    const pageName = window.location.pathname.split('/').pop();
    if (pageName === "chatbot") {
      document.title = `Polaris | ChatBot`;
    } else {
      document.title = `Polaris | ${pageTitle}`;
    }
  }, [pageTitle]);

  const isDashboardRoute = window.location.pathname.startsWith('/dashboard');

  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer />
        <ScrollTop />

        {error ? (
          isDashboardRoute ? (
            <DashboardLayout
              url={url}
              dashboardData={dashboardData}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              reportObject={reportObject}
              callChangeReportFilters={callChangeReportFilters}
              loading={dashboardLoading}
              notificationsData={notificationsData}
              language={language}
              onChangeLang={changeLang}
              setLanguage={setLanguage}
              translations={translations}
            >
              <ErrorComponent translations={translations}/>
            </DashboardLayout>
          ) : (
            <WesiteLayout url={url} loading={loading} translations={translations} language={language} setLanguage={setLanguage} >
              <ErrorComponent translations={translations} />
            </WesiteLayout>
          )
        ) : (
          <Routes>
            {isDashboardRoute ? (
              <Route
                path="/dashboard/*"
                element={
                  <DashboardLayout
                    url={url}
                    dashboardData={dashboardData}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    searchQuery={searchQuery}
                    regulationSearchQuery={regulationSearchQuery}
                    setSearchQuery={setSearchQuery}
                    setRegulationSearchQuery={setRegulationSearchQuery}
                    reportObject={reportObject}
                    callChangeReportFilters={callChangeReportFilters}
                    loading={dashboardLoading}
                    notificationsData={notificationsData}
                    language={language}
                    onChangeLang={changeLang}
                    setLanguage={setLanguage}
                    translations={translations}
                  >
                  <Routes>
                    <Route path="/" element={<Dashboard url={url} dashboardData={dashboardData}   translations={translations} language={language}/>} />
                    <Route path="/dashboard" element={<Dashboard url={url} dashboardData={dashboardData}  translations={translations} language={language} />} />
                    <Route path="/search" element={<Search url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} callChangeReportFilters={callChangeReportFilters} setCallChangeReportFilters={setCallChangeReportFilters} reportObject={reportObject} setReportObject={setReportObject} translations={translations}  language={language}  />} />
                    <Route path="/amendments" element={<Amendments url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} translations={translations} language={language}
                      callChangeReportFilters={callChangeReportFilters}
                      reportObject={reportObject} />} />
                    <Route path="/amendments/:name" element={<Amendments url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} searchQuery={searchQuery}  translations={translations} language={language}/>} />
                    <Route path="/reminders" element={<Reminders url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}  searchQuery={searchQuery} translations={translations} language={language} />} />
                    <Route path="/regulation" element={<Regulation url={url} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery}  setSearchQuery={setSearchQuery}  dashboardData={dashboardData} callChangeReportFilters={callChangeReportFilters} reportObject={reportObject} translations={translations} language={language}/>} />
                    <Route path="/reports" element={<ReportsPage url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} callChangeReportFilters={callChangeReportFilters} reportObject={reportObject} translations={translations} language={language} />} />
                    <Route path="/reports/:name" element={<ReportsDetails url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} callChangeReportFilters={callChangeReportFilters} setCallChangeReportFilters={setCallChangeReportFilters} reportObject={reportObject} setReportObject={setReportObject} translations={translations} language={language} />} />
                    <Route path="/notes" element={<Notes url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} callChangeReportFilters={callChangeReportFilters} setCallChangeReportFilters={setCallChangeReportFilters} reportObject={reportObject} setReportObject={setReportObject} translations={translations} language={language} />} />
                    <Route path="/regulation/:name" element={<RegulationDetails url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} searchQuery={searchQuery} translations={translations} />} />
                    <Route path="/profile" element={<ProfilePage url={url} dashboardData={dashboardData} translations={translations} language={language} />} />
                  </Routes>
                </DashboardLayout>
                
                }
              />
            ) : (
              <Route
                path="/*"
                element={
                <WesiteLayout url={url} loading={loading} translations={translations} language={language} setLanguage={setLanguage}>
                  <Routes>
                    <Route path="/" element={<Home url={url} data={data} translations={translations} language={language}/>} />
                    <Route path="home" element={<Home url={url} data={data} translations={translations} language={language}/>} />
                    <Route path="privacy" element={<Privacy data={data} translations={translations}/>} />
                    <Route path="pricing" element={<Pricing url={url} data={data}  translations={translations} language={language}/>} />
                    <Route path="payment" element={<PaymentPage translations={translations} url={url} />} />
                    <Route path="about" element={<AboutPage url={url} data={data}  translations={translations} language={language} />} />
                    <Route path="contact" element={<Contact url={url} data={data} translations={translations} language={language} />} />
                    <Route path="ourteam" element={<OurTeam url={url} data={data} translations={translations} language={language}/>} />
                    <Route path='bookdemo' element={<BookDemo url={url} data={data} translations={translations} language={language}/>} />
                    <Route path='freetrail' element={<Trail url={url} data={data} translations={translations} language={language} />} />
                    <Route path="/login" element={<Login url={url} translations={translations} language={language}/>} />
                    <Route path='/register' element={<Register url={url} data={data} translations={translations} language={language} />} />
                    <Route path="/checkemail" element={<CheckEmail translations={translations} />} />
                    <Route path="/emailverified" element={<EmailVerified translations={translations}/>} />
                    <Route path="/resetpassword/:email/:code" element={<ResetPassword url={url} translations={translations} />} />
                  </Routes>
                </WesiteLayout>
                }
              />
            )}

            <Route path="/dashboard/chatbot" element={<ChatBot url={url} translations={translations}/>} />

          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;

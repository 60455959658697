import React, { useState, useRef, useEffect } from 'react';
import "./Reports.css";
import { AddBtn, NotePopUp, ReminderPopUp, DeletBtn, ConfirmDelete } from '../../../../Components';
import addBtn from "../../../../assets/images/svg/addbtn.svg"
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Modal from "react-modal"
import { toast } from 'react-toastify';

const Reports = ({ reportID, reportName, reportFilters, reportNote, reportTopic, property, title, para, name, noteCount, reminderCount, titleLink, url, onRefresh,isSmallScreen,legId, translations }) => {
  const [showAddNote, setShowAddNote] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [showRegulationBtns, setShowRegulationBtns] = useState(false);
  // const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState({});

  const notePopUpRef = useRef(null);
  const reminderPopUpRef = useRef(null);
  const dateTimePickerRef = useRef(null);
  const showBtnsRef = useRef(null);  // Ref for the `show-btns` container


  const handleDelete = () => {
    setShowPopup(true);
  };

  const handleAddNoteClick = () => {
    setShowAddNote(!showAddNote);
    setShowAddReminder(false); // Close the reminder pop-up if it was open
  };

  const handleAddReminderClick = () => {
    setShowAddReminder(!showAddReminder);
    setShowAddNote(false); // Close the note pop-up if it was open
  };

  const handleShowBtnsClick = () => {
    if (isSmallScreen) {
      setShowRegulationBtns(!showRegulationBtns);
    }
  };


  const handleNoteClickOutside = (event) => {
    if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
      setShowAddNote(false);
    }
  };

  const handleClickOutsideReminder = (event) => {
    // Check if the click happened inside the ReminderPopUp
    const isClickInsideReminderPopup = reminderPopUpRef.current && reminderPopUpRef.current.contains(event.target);

    // Check if the click happened inside the DateTimePicker input wrapper
    const isClickInsideDatePicker = dateTimePickerRef.current && dateTimePickerRef.current.contains(event.target);

    // Check if the click happened inside the DateTimePicker's popper (dropdown)
    const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);

    // If the click is outside of the ReminderPopUp, DateTimePicker input, and DateTimePicker popper, close the ReminderPopUp
    if (!isClickInsideReminderPopup && !isClickInsideDatePicker && !isClickInsideDatePickerPopper) {
      setShowAddReminder(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutsideReminder);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideReminder);
    };
  }, []);


  const handleClickOutside = (event) => {
    const isClickInsideDatePickerPopper = document
      .querySelector('[role="dialog"]')
      ?.contains(event.target);
    if (
      showBtnsRef.current &&
      !showBtnsRef.current.contains(event.target) &&
      !isClickInsideDatePickerPopper
    ) {
      setShowRegulationBtns(false);
    }
  };



  useEffect(() => {
    const updatedQuery = {};
    reportFilters.forEach(filter => {
      updatedQuery[filter.field] = filter.value;
    });
    setQuery(updatedQuery);
  }, [reportFilters]);



  const confirmDeleteRegulationFromReport = async () => {
    const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report`;
    const data = {
      "name": reportID,
      "report_name": reportName,
      "topic": reportTopic,
      "note": reportNote,
      // "query": query,
      "remove": name
    };

    try {
      const apiKey = Cookies.get('api_key');
      const apiSecret = Cookies.get('api_secret');
      const response = await axios.put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
      if (response.data && response.data.status_code === 200) {
        toast.success(translations?.message?.regulationRemovedSuccessfuly ? translations.message.regulationRemovedSuccessfuly:'Regulation removed successfully.');
        onRefresh();
      } else {
        toast.error(translations?.message?.failedRemoveRegulation ? translations.message.failedRemoveRegulation:'Failed to remove the regulation from report.');
      }
    } catch (error) {
      toast.error(translations?.message?.failedRemoveRegulation ? translations.message.failedRemoveRegulation:'Failed to remove the regulation from report.');
    }
    setShowPopup(false);
  };



  const cancelDelete = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className='report'>
      <h1 className='report-property'>{property}</h1>
      <div className='report-title-container'>
        <Link reloadDocument to={`${titleLink}`} className='report-title-link'>
          <h1 className='report-title'>{title}</h1>
        </Link>
        {isSmallScreen && (
          <div className='show-btns' onClick={handleShowBtnsClick}>

          </div>
        )}
        {(isSmallScreen && showRegulationBtns) || !isSmallScreen ? (
          <div className='report-btns' ref={showBtnsRef}>
            <div ref={notePopUpRef} className={`NotePopUp ${showAddNote ? 'active' : ''}`}>
              <NotePopUp
                count={"4"}
                isOpen={showAddNote}
                onCancel={handleAddNoteClick}
                url={url}
                docName={legId}
                onRefresh={onRefresh}
                translations={translations}
              />
            </div>
            <div ref={reminderPopUpRef} className={`ReminderPopUp ${showAddReminder ? 'active' : ''}`}>
              <ReminderPopUp
                url={url}
                count={"4"}
                isOpen={showAddReminder}
                onCancel={handleAddReminderClick}
                docName={legId}
                onRefresh={onRefresh}
                translations={translations}
              />
            </div>
            <div onClick={handleAddNoteClick}>
              <AddBtn
                name={translations?.message?.addNote ? translations.message.addNote : "Add Note"}
                count={noteCount}
                img={addBtn}
              />
            </div>
            <div onClick={handleAddReminderClick}>
              <AddBtn
                name={translations?.message?.addReminder ? translations.message.addReminder : "Add Reminder"}
                count={reminderCount}
                img={addBtn}
              />
            </div>
            <div onClick={handleDelete}>
              <DeletBtn
                name={translations?.message?.deleteFromReport ? translations.message.deleteFromReport:"Delete From Report"}
              />
            </div>
          </div>
        ) : null}
      </div>
      <p className='report-para'>{para}</p>
      <Modal isOpen={showPopup} onRequestClose={cancelDelete}>
        <ConfirmDelete
          cancleDelete={cancelDelete}
          confirmDelete={confirmDeleteRegulationFromReport}
          para= {translations?.message?.areSureDelete ? translations.message.areSureDelete : "Are you sure you want to delete this?"}
          deletePara={translations?.message?.yesDelete ? translations.message.yesDelete : "Yes, Delete"}
          translations={translations}
        />
      </Modal>
    </div>
  );
};

export default Reports;
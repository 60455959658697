import React, { useState, useEffect } from 'react';
import "./Payment.css";
import { Container, Row } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import Visa from '../../../../Assets/images/Payment/Visa.png';
import Paypal from '../../../../Assets/images/Payment/paypal.png';
import Alipay from '../../../../Assets/images/Payment/alipay.png';
import { SpinnerLoading } from '../../../../../Dashboard/Components';

const Payment = ({ translations, url }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [planData, setPlanData] = useState({});
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingPlanData, setLoadingPlanData] = useState(true); // Add loading state for plan data
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planType = queryParams.get('planType');
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentError, setPaymentError] = useState('');

  useEffect(() => {
    // Fetch plan data when the component mounts
    const fetchPlanData = async () => {
      setLoadingPlanData(true); // Start loading plan data
      try {
        const response = await fetch(`${url}/api/method/erpnext.accounts.doctype.subscription_plan.subscription_plan.subscription_plan?name=${planType}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch plan data');
        }
        
        const data = await response.json();
        setPlanData(data.result);
      } catch (error) {
        console.error('Error fetching plan data:', error);
      } finally {
        setLoadingPlanData(false); // Stop loading plan data
      }
    };

    fetchPlanData();
  }, [url, planType]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePayment = async () => {
    if (!selectedOption) {
      setPaymentError("Please select a payment method"); 
      return; 
    }
    try {
      setLoadingPayment(true); // Start loading
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");

      if (!apiKey || !apiSecret) {
        setErrorMessage("You are not logged in");
        setLoadingPayment(false); // Stop loading
        return; // Exit if the user is not logged in
      }

      const response = await fetch(`${url}/api/method/erpnext.accounts.doctype.subscription.subscription.subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
        body: JSON.stringify({
          plans: [
            {
              name: planType,
              qty: 1,
            },
          ],
          payment_gateway: selectedOption,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Payment submission failed');
      }
  
      const data = await response.json();
      console.log('Payment successful:', data);
  
      if (data.message && data.message.payment_url) {
        window.location.href = data.message.payment_url;
      } else {
        setErrorMessage("Payment URL not found in response data.");
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage("An error occurred while processing the payment.");
    } finally {
      setLoadingPayment(false); // Stop loading
    }
  };

  const calculateTotal = () => {
    const price = parseFloat(planData.price || 0);
    const discount = parseFloat(planData.discount || 0);
    const taxes = parseFloat(planData.taxes || 0);
    return price + discount + taxes;
  };

  return (
    <Container>
      <Row>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div>
            <div className="payment-title">
              {translations?.message?.pleaseSelectPaymentMethod || "Please Select the payment method"}
            </div>
            <div className="payment-text">
              {translations?.message?.meetUniquHr || "Meet the unique HR needs of your organization with options for all kinds of workplaces."}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-8"></div>
      </Row>
      <Row>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className='payment-card'>
            <label className="label-radio">
              <input
                type="radio"
                value="PayPal"
                checked={selectedOption === "PayPal"}
                onChange={handleOptionChange}
              />
              {translations?.message?.payPal || "PayPal"}
            </label>
            <img src={Paypal} alt="Paypal" />
          </div>
          {
            !selectedOption && paymentError && (
              <div className='error-message'>
                {paymentError}
              </div>
            )
          }
        </div>
        <div className="col-sm-12 col-md-6 col-lg-8 payment-container">
          {loadingPayment && (
            <div className='payment-loading'>
              <SpinnerLoading />
            </div>
          )}
          <div className={`${loadingPayment ? 'payment-container-loading' : ''}`}>
            {errorMessage && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}
            <div className='package-title'>{`${planData.id || ''} - ${planData.billing_interval_count || ''} ${planData.billing_interval || ''}`}</div>
            <div className='plan-details'>
              <div className='flex-div'>
                <div>{translations?.message?.planDiscount || "Price"}</div>
                <div className='package-price'>{planData.formatted_price || ''}</div>
              </div>
              <div className='flex-div'>
                <div>{translations?.message?.planDiscount || "Plan Discount"}</div>
                <div className='package-price discount'>$0</div>
              </div>
              <div className='flex-div'>
                <div>{translations?.message?.taxesAndFees || "Taxes & Fees"}</div>
                <div className='package-price'>$0</div>
              </div>
            </div>
            <div className='flex-div'>
              <div className='package-title'>{translations?.message?.total || "TOTAL"}</div>
              <div className='total-price'>
                {loadingPlanData ? <SpinnerLoading /> : `$${calculateTotal().toFixed(2)}`}
              </div>
            </div>
            <div>
              <button className='btn-payment' onClick={handlePayment} disabled={loadingPayment}>
                {translations?.message?.sumbitSecurePayment || "Submit Secure Payment"}
              </button>
            </div>
            <div className='package-text'>
              {translations?.message?.byCheckingOutYouAgree || "By checking out you agree with our"} 
              <a>{translations?.message?.termsOfService || "Terms of Service"}</a>.  
              {translations?.message?.weWillProcessYourPersonal || "We will process your personal data for the fulfillment of your order and other purposes as per our"}  
              <a>{translations?.message?.privacyPolicy || "Privacy Policy"}</a>. 
              {translations?.message?.canCancelRecurringPaymentAtAnyTime || "You can cancel recurring payments at any time."}
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default Payment;

import React, { useState } from 'react';
import "./TeamCard.css"

const TeamCard = ({ image, title, secondTitle, para, translations }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLongNote, setIsLongNote] = useState(false);


  const MAX_LENGTH = 100; // Adjust the length threshold as needed

  // Check if the para is long and set the state accordingly
  React.useEffect(() => {
    setIsLongNote(para.length > MAX_LENGTH);
  }, [para]);

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getTruncated = (maxLength) => {
    let truncated = para.slice(0, maxLength) + '...';
    return truncated;
  };

  return (
    <div className='team-card' data-aos="zoom-in" data-aos-duration="1500">
      <div className='team-card-image' data-aos="zoom-in" data-aos-duration="1500">
        <img className='team-card-img' src={image} />
      </div>
      <h1 className='team-card-title'>{title}</h1>
      <h1 className='team-card-second-title'>{secondTitle}</h1>
      <p className='team-card-para'>{isLongNote && !isExpanded ? getTruncated(MAX_LENGTH) : para}</p>
      {isLongNote && (
        <a className='team-card-link' onClick={handleReadMore} style={{ cursor: 'pointer' }}>
          {isExpanded ? translations?.message?.showLess ? translations.message.showLess:' Show Less' : translations?.message?.readMore ? translations.message.readMore:' Read More'}
        </a>
      )}
    </div>
  )
}

export default TeamCard
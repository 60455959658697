import React, { useRef } from "react";
import "./ProfileInfo.css";
import Camera from "../../../../assets/images/svg/camera-icon.svg";

const ProfileInfo = ({ name, email, ProfilePhoto, onPhotoChange, photoName }) => {
  const fileInputRef = useRef(null);

  const handlePhotoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderProfileContent = () => {
    if (ProfilePhoto) {
      return <img src={ProfilePhoto} className="profile-img" alt="Profile" />;
    } else {
      const firstLetter = name && name.trim().length > 0 ? name.charAt(0).toUpperCase() : "N/A";
      return <div className="profile-initial">{firstLetter}</div>;
    }
  };

  return (
    <div className="profile-info">
      <div className="row">
        <div className="col-12">
          <div className="tab-photo">{photoName}</div>
        </div>
        <div className="col-12 profile-photo-div-parent">
          <div className="profile-photo-div">
            <button
              type="button"
              className="custom-file-upload"
              onClick={handlePhotoClick}
            >
              {renderProfileContent()}
              <img src={Camera} className="c1" alt="Camera Icon" />
            </button>
            <input
              ref={fileInputRef}
              id="select_files"
              type="file"
              accept="image/x-png,image/png,image/jpeg,image/jpg,image/svg+xml"
              onChange={onPhotoChange}
              style={{ display: "none" }}
            />
          </div>
          <div>
            <div className="profile-text-info">
              <div className="profile-name">{name}</div>
              <div className="profile-email">{email}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;

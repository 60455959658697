import React from 'react'
import "./Text.css"

const Text = ({ para }) => {
    return (
        <h1 className='text'>
            {para}
        </h1>
    )
}

export default Text

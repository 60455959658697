import React from 'react';
import "./AiBtn.css";
import aiIcon from "../../assets/images/svg/ai-icon.svg";

const AiBtn = () => {
    return (
        <div className='ai-btn'>
            <a class="btn-glow btn-hover-shine" href={"/dashboard/chatbot"}>
                {/* AI lawyer */}
                <img src={aiIcon}/>
            </a>
        </div>
    );
}

export default AiBtn;

import React from 'react'
import "./Alert.css"
import warning from "../../assets/images/svg/warning.svg"

const Alert = ({ border, bg, para, linkHref, linkPara, linkColor, showWarningImage, showLink, paraColor }) => {
  return (
    <div className='alert' style={{ borderLeft: `7px solid ${border}`, background: `${bg}` }}>
        <div className='alert-content'>
            {showWarningImage && <img src={warning} />}
            <p className='alert-para' style={{ color: `${paraColor}` }}>{para}</p>
        </div>
        {showLink && (
          
                <a href={`${linkHref}`} className='alert-link' style={{ color: `${linkColor}` }}>
                    {linkPara}
                </a>
           
        )}
    </div>
  )
}

export default Alert
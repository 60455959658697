import React from 'react'
import "./Dashboard.css"
import { Reminders, Updates, Amendments, Announcements, Regulations } from '../../Features/Dashboard'
import { Container, Row, Col } from 'react-bootstrap'


const Dashboard = ({ url , dashboardData, translations, language}) => {
    return (
        <div className="dashboard container">
            
            <Row className='top-dashboard-section'>
                <Col xl={6}>
                    <Regulations url={url} data={dashboardData} translations={translations} language={language}/>
                </Col>
                <Col xl={6}>
                    <Updates dashboardData={dashboardData} translations={translations} />
                </Col>
            </Row>

            <Row className='bottom-dashboard-section'>
                <Col xl={6}>
                    <Amendments url={url} data={dashboardData} translations={translations} language={language}/>
                </Col>
                <Col xl={6}>
                    {/* <Announcements url={url} data={dashboardData} translations={translations}/> */}
                    <Reminders url={url} data={dashboardData} translations={translations} language={language}/>
                </Col>
            </Row>

        </div>
    )
}

export default Dashboard

import React from 'react'
import "./UnderDevelopment.css"
import warning from "../../assets/images/svg/warningPopup.svg"

const UnderDevelopment = ({ btnClick, translations }) => {
  return (
    <div className='popup'>
        <div className='popup-container'>
            <img src={warning} />
            <div className='popup-content'>
                <h1 className='popup-title'>{translations?.message?.note ? translations.message.note :"Note"}</h1>
                <p className='popup-para'>{translations?.message?.featureUnderDevelopment ? translations.message.featureUnderDevelopment :"feature under development"}</p>
            </div>
        </div>
        <button className='popup-btn' onClick={btnClick}>{translations?.message?.close ? translations.message.close :"Close"}</button>
    </div>
  )
}

export default UnderDevelopment
import React from 'react'
import "./Count.css"
import CountUp from 'react-countup';

const Count = ({ count, title}) => {
    return (
        <div className='count' data-aos="zoom-in" data-aos-duration="1800">
            <div className='count-num'>
                <CountUp
                    start={0}
                    end={count}
                    duration={3}  
                    onEnd={({ pauseResume, reset, start, update }) => start()}
                />
                <h1>+</h1>
            </div>
            <h1 className='count-title'>{title}</h1>
        </div>
    )
}

export default Count

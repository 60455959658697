import * as React from 'react';
import { useState, useEffect } from "react";
import "./EmployeeList.css";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { frFR, enUS } from '@mui/x-data-grid/locales';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddEmployeeModal from '../AddEmployeeModal/AddEmployeeModal';
import { Empty } from 'antd';
import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import { SpinnerLoading,ConfirmDelete } from "../../../../Components"; // Ensure this component is imported correctly
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal';



export default function EmployeeList({ template, rowId, url, translations, language }) {
  const employeeProfileData = template?.data?.content?.employees?.objects || [];
  let paginationData = template?.data?.content?.employees?.pagination || {};
  const [gridKey, setGridKey] = useState(0); // Add a key state

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(paginationData.metadata?.current_page - 1 || 0); // API pages are typically 1-indexed
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false); // State for modal visibility
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(paginationData.metadata?.total_pages || 1);
  const [totalData, setTotalData] = useState(paginationData.metadata?.total_data || 1);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [rowEmail, setRowEmail] = useState(null);
  

  
  const cancelDelete = () => {
    setShowPopup(false);
};

  useEffect(() => {
    fetchData(0, 10); // Fetch data when the component mounts
  }, []);

  useEffect(() => {
    fetchData(0, 10); // Fetch data when totalData changes
    setGridKey((prevKey) => prevKey + 1); // Update the key to force re-render
    // console.log("totalData",totalData);
  }, [totalData]); // Run this effect when totalData changes

  useEffect(() => {
    setRows(employeeProfileData.map((employee, index) => {
      const statusClass = employee.enabled ? 'enable' : 'disable';
      return {
        id: index + 1, // Assign a unique id to each row, but it's not displayed in the grid
        img: (
          <Avatar
            src={employee.user_image ? url + employee.user_image : employee.full_name}
            alt={employee.full_name}
            sx={{ width: 40, height: 40 }}
          />
        ),
        name: `${employee.first_name} ${employee.last_name}`,
        email : `${employee.name}`,
        status: employee.enabled ? <div className='status enable'>.
        
        {translations?.message?.active ? translations.message.active:'Active'}
        </div> : <div className='status disable'>
          
          {translations?.message?.inActive ? translations.message.inActive:'InActive'}
          </div>,
        statusClass: statusClass, // Store the status class in the row data
       
      };
    }));
  }, [employeeProfileData, url]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchData(newPage, rowsPerPage);
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = (newEmployee) => {
    // console.log("New Employee Data:", newEmployee);
  };




    const fetchData = async (page, rowsPerPage) => {
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;

      const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.employee?start=${offset}&limit=${limit}`;
      try {
        const apiKey = Cookies.get("api_key");
        const apiSecret = Cookies.get("api_secret");
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${apiKey}:${apiSecret}`,
          },
        });
       
        if (response.data && response.data.status_code === 200) {
          const updatedEmployeeProfileData =
          response.data?.result?.objects|| [];
        

          setRows(
            response.data?.result?.objects.map((employee, index) => {
              const statusClass = employee.enabled ? 'enable' : 'disable';
              return {
                id: index + 1, // Assign a unique id to each row
                img: (
                  <Avatar
                    src={url + employee.user_image}
                    alt={employee.full_name}
                    sx={{ width: 40, height: 40 }}
                  />
                ),
                name: `${employee.first_name} ${employee.last_name}`,
                email : `${employee.name}`,
                status: employee.enabled ? <div className='status enable'>
                  {translations?.message?.active ? translations.message.active:'Active'}
                  </div> : <div className='status disable'>{translations?.message?.inActive ? translations.message.inActive:'InActive'}</div>,
                statusClass: statusClass, // Store the status class in the row data
                     };
            })
          );

          setTotalPages(response.data.result?.pagination?.metadata?.total_pages || 1);
          setTotalData(response.data.result?.pagination?.metadata?.total_data || 1);
        } else {
        }
      } catch (error) {
        toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
      }
    };


    const handleDelete = async (selectedEmails) => {
      setLoadingDelete(true);
    
      // Retrieve the API credentials from cookies
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
    
      // Prepare the data payload
      const data = {
        "users": selectedEmails
      };
    
      console.log("Data:", data);
    
      try {
        // Construct the API URL
        const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.employee`;
        // Make the DELETE request
        const response = await axios({
          method: 'DELETE',
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${apiKey}:${apiSecret}`,
          },
          data: JSON.stringify(data),
        });
        console.log(data)

        if (response.data && response.data.status_code === 200) {
          toast.success(translations?.message?.employeeDeletedSuccessfuly ? translations.message.employeeDeletedSuccessfuly:"Employee deleted successfully.");
          setShowPopup(false);
          // Optionally, reload data after successful deletion
          fetchData(0, 10);
        } else {
          toast.error(response.data.message || translations?.message?.errorOccurredWhileDeletingEmployees ? translations.message.errorOccurredWhileDeletingEmployees:"An error occurred while deleting employees. Please try again.");
        }
    
      } catch (error) {
        // Improved error handling
        console.error("Error deleting employees:", error);
        toast.error(error.response?.data?.message || translations?.message?.errorOccurredWhileDeletingEmployees ? translations.message.errorOccurredWhileDeletingEmployees:"An error occurred while deleting employees. Please try again.");
      }
      finally {
        setShowPopup(false);
        setLoadingDelete(false);
      }
    };


    const columns = [
      {
        field: 'img',
        headerName: '',
        width: 80,
        renderCell: (params) => params.value,
        sortable: false,
        editable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      { field: 'name', headerName: translations?.message?.employeeName ? translations.message.employeeName:'Employee Name', width: 200, editable: false, renderCell: (params) => params.value },
      { field: 'status', headerName: translations?.message?.status ? translations.message.status:'Status', width: 120, renderCell: (params) => params.value },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        sortable: false,
        editable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => <ActionMenu rowId={params.row.id}  email={params.row.email}  url={url} fetchData={fetchData} loadingDelete={loadingDelete} setLoadingDelete={setLoadingDelete} setShowPopup={setShowPopup}  showPopup={showPopup} cancelDelete={cancelDelete}  setRowEmail={setRowEmail} translations={translations}/>,
        align: 'right',
        headerAlign: 'right'
      },
      {
        field: "delete",
        width: 75,
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => {
          if (selectionModel.length > 0) {
            return (
              <IconButton
                onClick={() => {
                  setShowPopup(true); // Show the confirmation dialog
                  const selectedIDs = new Set(selectionModel);
                  const selectedEmails = rows
                  .filter(row => selectedIDs.has(row.id)) // Filter rows based on selected IDs
                  .map(row => row.email); // Extract emails from filtered rows
                        handleDelete(selectedEmails);
                  
                }}
              >
                <DeleteIcon />
              </IconButton>
            );
          } else {
            return null; // Don't render the delete column if no rows are selected
          }
        }
      }
    ];
    

  return (
    <>
      <div className="employee-list-container">
        <div className="note-employee">
          
          {translations?.message?.numberEmployeeAdd ? translations.message.numberEmployeeAdd:'Number of employees you can add: '}
          
          <span style={{ color: "#2DB0D8" }}>5 {translations?.message?.employee ? translations.message.employee:'Employee'}</span>{" "}
        </div>
        <button className="btn-add-employee" onClick={handleOpen}>
        {translations?.message?.addEmployee ? translations.message.addEmployee:'Add New Employee'} +
        </button>
        <div className="employee-list-content">
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              key={gridKey} // Add a key prop to force re-render
              rows={rows} // Use your dynamically fetched rows here
              columns={columns} // Use your defined columns with action buttons
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              disableRowSelectionOnClick
              rowCount={totalData}
              localeText={language=="fr"?frFR.components.MuiDataGrid.defaultProps.localeText:
                enUS.components.MuiDataGrid.defaultProps.localeText

              } 
            />
          </Box>
        </div>
        <AddEmployeeModal
          url={url}
          open={open}
          handleClose={handleClose}
          handleSave={handleSave}
          fetchData={fetchData}
          translations={translations}
        />
      </div>
      <div>
        <Modal
          isOpen={showPopup}
          onClose={cancelDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              width: "350px !important",
              minHeight: "153px !important",
              maxHeight: "214px !important",
            }}
          >
            {loadingDelete ? (
              <div className="loading-delete">
                  <div className="profile-page-loading-container">
                    <div className="profile-page-loading">
                      <SpinnerLoading />
                    </div>
                  </div>
              </div>
            ) : (
              <ConfirmDelete
                para= {translations?.message?.areSureDelete ? translations.message.areSureDelete : "Are you sure you want to delete this?"}
                cancleDelete={cancelDelete}
                deletePara={translations?.message?.yesDelete ? translations.message.yesDelete : "Yes, Delete"}
                confirmDelete={() => handleDelete([rowEmail])}
                translations={translations}
              />
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

function ActionMenu({ email ,setShowPopup, setRowEmail, translations}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRowEmail(null)
  };

  // const handleEdit = () => {
  //   console.log("Edit employee with id:", rowId);
  //   handleClose();
  // };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {/* <MenuItem onClick={handleEdit}>
          Edit
        </MenuItem> */}
        <>
            <MenuItem  onClick={() => {
              setShowPopup(true); // Show the confirmation dialog
              setRowEmail(email)
              setAnchorEl(null);
            }}>
              {translations?.message?.delete ? translations.message.delete:'Delete'}
            </MenuItem>
        </>
      </Menu>
    </div>
  );
}

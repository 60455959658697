import React, { useState, useEffect } from "react";
import "./CompanyProfile.css";
import { Row, Col } from "react-bootstrap"
import ProfileInfo from "../ProfileInfo/ProfileInfo";
import PhoneInput from "react-phone-number-input";
import Cookies from 'js-cookie';
import { SpinnerLoading } from "../../../../Components"; // Ensure this component is imported correctly
import { toast } from 'react-toastify';
import fr from 'react-phone-number-input/locale/fr'
import en from 'react-phone-number-input/locale/en'

const CompanyProfile = ({ url, template, translations, language }) => {

  const apiKey = Cookies.get('api_key');
  const apiSecret = Cookies.get('api_secret');

  const companyProfileData = template?.data?.content?.company_profile || {};



  const [provincesArray, setProvincesArray] = useState([]);

  useEffect(() => {
    const provincesArray = companyProfileData.provinces
      ? Array.isArray(companyProfileData.provinces)
        ? companyProfileData.provinces
        : companyProfileData.provinces.split(",")
      : [];
    setProvincesArray(provincesArray);
  }, [companyProfileData]); 

  
  const [checkedItems, setCheckedItems] = useState({});
  const [prevCheckedItems, setPrevCheckedItems] = useState({});
  const [companyProfile, setCompanyProfile] = useState({
    company_name: companyProfileData.customer_name || "",
    email: companyProfileData.email_id || "",
    phone_number: companyProfileData.mobile_no || "",
    company_image: companyProfileData.image || "",
    provinces: provincesArray || [] // Initialize provinces
  });
  
  const [editedCompanyProfile, setEditedCompanyProfile] = useState({
    company_name: companyProfileData.customer_name || "",
    email: companyProfileData.email_id || "",
    phone_number: companyProfileData.mobile_no || "",
    company_image: companyProfileData.image || "",
    provinces: provincesArray || [] // Initialize provinces
  });
  

  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [companyPhotoPreview, setCompanyPhotoPreview] = useState(companyProfileData.image ? `${url}${companyProfileData.image}` : null);
  const [isEdited, setIsEdited] = useState(false); // State to track if there are changes
  const [isEditedCheckList, setIsEditedCheckList] = useState(false); // State to track if there are changes
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [jurisdictionData, setJurisdictionData] = useState([]);


  useEffect(() => {
    fetch(`${url}/api/method/projectx.projectx.doctype.jurisdiction.jurisdiction.jurisdiction`, {
      method: "GET",
      headers: {
        Authorization: `token ${apiKey}:${apiSecret}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setJurisdictionData(result.result);
  
        // Initialize checked items based on `provincesArray`
        const initialCheckedItems = result.result.reduce((acc, item) => {
          if (provincesArray.includes(item.name)) {
            acc[item.id] = true;
          }
          return acc;
        }, {});
        setCheckedItems(initialCheckedItems);
        setPrevCheckedItems(initialCheckedItems);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [provincesArray]);

  useEffect(() => {
    setPrevCheckedItems(checkedItems);
  }, []);

  const handleChange = (event) => {
    const { name, checked } = event.target;
  
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [name]: checked,
      };
  
      // Create an array of province objects with the format { name: "province_name" }
      const updatedProvinces = Object.keys(updatedCheckedItems)
        .filter((key) => updatedCheckedItems[key])
        .map((id) => {
          const provinceName = jurisdictionData.find((item) => item.id === id)?.name;
          return { name: provinceName };  // Wrap each province name in an object
        })
        .filter(Boolean);
  
      // Update the edited company profile with the selected provinces
      setEditedCompanyProfile((prevProfile) => ({
        ...prevProfile,
        provinces: updatedProvinces,
      }));
  
      if (JSON.stringify(updatedProvinces) !== JSON.stringify(companyProfile.provinces)) {
        setIsEditedCheckList(true);
      } else {
        setIsEditedCheckList(false);
      }
  
      return updatedCheckedItems;
    });
  };
  
  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedCompanyProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
    checkIfEdited(name, value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyPhoto(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyPhotoPreview(reader.result);
        setIsEdited(true);
      };
      reader.readAsDataURL(file);
    } else {
      setCompanyPhoto(null);
    }
  };

  const checkIfEdited = (name, value) => {
    const hasProvincesChanged =
      JSON.stringify(editedCompanyProfile.provinces) !== JSON.stringify(companyProfile.provinces);
  
    if (
      (name === "company_name" && value !== companyProfile.company_name) ||
      (name === "email" && value !== companyProfile.email) ||
      (name === "phone_number" && value !== companyProfile.phone_number) ||
      companyPhoto ||
      hasProvincesChanged
    ) {
      setIsEdited(true);
    } else {
      const { company_name, email, phone_number } = editedCompanyProfile;
      if (
        company_name === companyProfile.company_name &&
        email === companyProfile.email &&
        phone_number === companyProfile.phone_number &&
        !companyPhoto &&
        !hasProvincesChanged
      ) {
        setIsEdited(false);
      }
    }
  };

  const handleCancel = () => {
    setEditedCompanyProfile({
      company_name: companyProfile.company_name,
      email: companyProfile.email,
      phone_number: companyProfile.phone_number,
      company_image: companyProfile.company_image,
    });
    setCompanyPhoto(null);
    setCompanyPhotoPreview(companyProfileData.image ? `${url}${companyProfileData.image}` : null);
    setIsEdited(false);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
  
    try {
      // Construct the payload as a plain JavaScript object
      const payload = {
        company_name: editedCompanyProfile.company_name,
        email: editedCompanyProfile.email,
        phone_number: editedCompanyProfile.phone_number,
        // Include the provinces as an array of objects
        provinces: Object.keys(checkedItems)
          .filter((key) => checkedItems[key])
          .map((id) => {
            return { name: jurisdictionData.find((item) => item.id === id)?.name };
          }),
      };
  
      // Handle company photo upload if there is a photo
      if (companyPhoto) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const photoData = {
            filename: companyPhoto.name,
            data: reader.result.split(',')[1],
          };
  
          // Add the logo information to the payload
          payload.logo = JSON.stringify(photoData);
  
          // Make the API call using the constructed payload
          const response = await fetch(`${url}/api/method/erpnext.selling.doctype.customer.customer.company`, {
            method: 'PUT',
            headers: {
              'Authorization': `token ${apiKey}:${apiSecret}`,
              'Content-Type': 'application/json', // Make sure to set the correct Content-Type
            },
            body: JSON.stringify(payload), // Send the payload as JSON
          });
  
          if (!response.ok) {
            const data = await response.json();
            toast.error(data.message);
            handleCancel();
            setIsLoading(false);
            return;
          }
  
          const data = await response.json();
          setCompanyProfile({
            ...editedCompanyProfile,
            company_image: `/files/${photoData.filename}`,
          });
          setIsEdited(false);
          setIsLoading(false);
        };
        reader.readAsDataURL(companyPhoto);
      } else {
        // If there is no photo, simply send the payload as is
        const response = await fetch(`${url}/api/method/erpnext.selling.doctype.customer.customer.company`, {
          method: 'PUT',
          headers: {
            'Authorization': `token ${apiKey}:${apiSecret}`,
            'Content-Type': 'application/json', // Set Content-Type as application/json
          },
          body: JSON.stringify(payload), // Send the payload as JSON
        });
  
        if (!response.ok) {
          const data = await response.json();
          toast.error(data.message);
          handleCancel();
          setIsLoading(false);
          return;
        }
  
        const data = await response.json();
        setCompanyProfile(editedCompanyProfile);
        setIsEdited(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      handleCancel();
      setIsLoading(false);
    }
  };
  
  
  





  

  

  const handleCancelCheckList = () => {
    setCheckedItems(prevCheckedItems)
    setIsEditedCheckList(false);
  };

  useEffect(() => {
    checkIfEdited("phone_number", editedCompanyProfile.phone_number);
  }, [editedCompanyProfile.phone_number]);

  return (

    isLoading ? (
      <div className="profile-page-loading-container">
        <div className="profile-page-loading">
          <SpinnerLoading />
        </div>
      </div>
    ) : (
      <div>
        <ProfileInfo
          name={companyProfile.company_name}
          email={companyProfile.email}
          ProfilePhoto={companyPhotoPreview || (companyProfile.company_image ? url + companyProfile.company_image : require("../../../../assets/images/svg/company-logo.png"))}
          onPhotoChange={handlePhotoChange}
          photoName= {translations?.message?.companyPhoto ? translations.message.companyPhoto : "Company Photo"}
        />
        <div>
          <div className="-tab profile-form2">
            <div className="user-edit">
              <span className="tab-photo">
                {translations?.message?.companyDetails ? translations.message.companyDetails : "Company Details"}
                </span>
            </div>
            <form className="row new-profile-form">
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor="tbCompanyName">
                     {translations?.message?.companyName ? translations.message.companyName : "Company Name"}
                  </label>
                  <input
                    type="text"
                    className="form-control profile-form-input"
                    id="tbCompanyName"
                    name="company_name"
                    value={editedCompanyProfile.company_name}
                    onChange={handleInputChange}
                    placeholder={translations?.message?.companyName ? translations.message.companyName : "Company Name"}

                  />
                </div>
              </div>
              {/* <div className="col-lg-4 top-label">
              <div className="form-group">
                <label className="profile-label" htmlFor="tblocation">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control profile-form-input"
                  id="tblocation"
                  name="location"
                  value={editedCompanyProfile.location}
                  onChange={handleInputChange}
                  placeholder="Location"
                />
              </div>
            </div> */}
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor="tbEmail">
                  {translations?.message?.email ? translations.message.email : "Email"}
                  </label>
                  <input
                    type="email"
                    className="form-control profile-form-input"
                    id="tbEmail"
                    name="email"
                    value={editedCompanyProfile.email}
                    onChange={handleInputChange}
                    placeholder={translations?.message?.email ? translations.message.email : "Email"}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group top-label">
                  <label className="profile-label" htmlFor="tbPhoneNumber">
                  {translations?.message?.phoneNumber ? translations.message.phoneNumber : "Phone Number"}
                  </label>
                  <PhoneInput
                    type="tel"
                    className=""
                    labels={language=="fr"?fr: en}
                    placeholder={translations?.message?.phoneNumber ? translations.message.phoneNumber : "Phone Number"}
                    value={editedCompanyProfile.phone_number}
                    onChange={(value) =>
                      setEditedCompanyProfile((prevProfile) => ({
                        ...prevProfile,
                        phone_number: value,
                      }))
                    }
                  />
                </div>
              </div>
             
            </form>
          </div>
        </div>

        <div>
        <div className="-tab profile-form2">
          <div className="user-edit">
            <span className="tab-photo">Provinces and Territories List</span>
          </div>
          <div>
            <Row>
              {jurisdictionData.map((item, index) => (
                <Col lg={3} style={{ paddingTop: "20px" }} key={index}>
                  <label className="provinces-label">
                    <input
                      type="checkbox"
                      name={item.id}
                      checked={checkedItems[item.id] || false}
                      onChange={handleChange}
                      className="custom-checkbox"
                    />
                    {item.name}
                    <span className="checkmark"></span>
                  </label>
                </Col>
              ))}
            </Row>
          </div>

          {(isEditedCheckList || isEdited) && (
            <div className="button-container">
              <a className="btn-user-details" style={{ color: "#828282" }} onClick={() => { handleCancel(); handleCancelCheckList(); }}>
                {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
              </a>
              <a onClick={handleSave} className="btn-user-details" style={{ paddingLeft: "30px", cursor: "pointer" }}>
                {translations?.message?.save ? translations.message.save : "Save"}
              </a>
          </div>
          )}
        </div>
      </div>
      </div>)
  );
};

export default CompanyProfile;

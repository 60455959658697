import React from 'react'
import "./Btn.css"
import { Button } from 'react-bootstrap'

const Btn = ({ name, bg, color }) => {
    return (
        <Button className='global-btn' style={{ backgroundColor: `${bg}`, color: `${color}`}}>
            {name}
        </Button>
    )
}

export default Btn

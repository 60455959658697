import React from 'react'
import "./ConfirmDelete.css"
import Delete from "../../assets/images/svg/warningPopup.svg"
import DeleteR from "../../assets/images/svg/warningPopupR.svg"

const ConfirmDelete = ({ confirmDelete, cancleDelete, para, deletePara ,isDisabled, translations}) => {
  const confirmDeleteClass = isDisabled === 1 ? 'activate' : 'deactivate';
  const deleteImage = isDisabled === 1 ? Delete : DeleteR;
 
  return (
    <div className='confirm-delete-container'>
        <div className='confirm-delete-content'>
            <img className='delete-img' src={deleteImage} />
            <h1 className='delete-title'>{translations?.message?.areSure ? translations.message.areSure : "Are you sure?"}</h1>
            <p className='delete-para'>{para}</p>
        </div>
        <div className='confirm-btns'>
            <button className='cancle-delete' onClick={cancleDelete}>
            {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
              </button>
            <button className={`confirm-delete ${confirmDeleteClass}`} onClick={confirmDelete}>{deletePara}</button>
        </div>
    </div>  
  )
}

export default ConfirmDelete
import React, { useState, useEffect } from 'react'
import "./ReportsDetails.css"
import { Title, DatePickers, Text } from "../../Components"
import { Results } from "../../Features/ReportsDetails"
import titleImg from "../../assets/images/svg/reports.svg"
import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

 
const ReportsDetails = ({ url, dashboardData, selectedFilters,setSelectedFilters, searchQuery,setSearchQuery,callChangeReportFilters, reportObject,setCallChangeReportFilters, setReportObject, translations , language}) => {
    const reportSection = dashboardData?.find(item => item.section === "Reports List");
    const [reportData, setReportData] = useState({});
    const reportName = window.location.pathname.split('/').filter(Boolean).pop();
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });

    useEffect(() => {
        fetchData();
    }, [reportName]);

    useEffect(() => {
        fetchData();
    }, [selectedDate]);

    // useEffect(() => {
    //     localStorage.setItem('returnedFrom', "ReportDetails");
    // }, []);

    const handleResetDate = () => {
        setSelectedDate(null); // Reset the selected date
      };
    
    
      const handleDateChange = (date) => {
    
          if (!date || !date.startDate || !date.endDate) {
              // Handle the case where date or startDate or endDate is null
              return;
       }
        
          // Function to format date as "Y-%m-%d %H:%M:%S"
          const formatDate = (inputDate) => {
            if (!inputDate) return null;
            const year = inputDate.getFullYear();
            const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const day = inputDate.getDate().toString().padStart(2, '0');
        
            return `${year}-${month}-${day}`;
          };
        
          // Set time to midnight for the start date and format it
          const startDateTime = date.startDate ? formatDate(new Date(date.startDate)) : null;
        
          // Set time to midnight for the end date and format it
          const endDateTime = date.endDate ? formatDate(new Date(date.endDate)) : null;
        
          setSelectedDate({
            startDate: startDateTime,
            endDate: endDateTime
          });
        };




    useEffect(() => {
        if (reportData && reportData.filters) {
            const updatedFilters = {};
            
            reportData.filters.forEach(filter => {
                if (filter.field === 'area_of_laws' || filter.field === 'topics' || filter.field === 'jurisdictions') {
                    if (!updatedFilters[filter.field]) {
                        updatedFilters[filter.field] = [];
                    }
                    // Split the filter.value by comma
                    const values = filter.value.split(',');
                    values.forEach(value => {
                        if (updatedFilters[filter.field].includes(value.trim())) {
                            updatedFilters[filter.field] = updatedFilters[filter.field].filter(
                                existingValue => existingValue !== value.trim()
                            );
                        } else {
                            updatedFilters[filter.field].push(value.trim());
                        }
                    });
                }
            });
            setSelectedFilters(updatedFilters);
        }
        setReportObject({
            "name": reportData.name,
            "report_doctype": reportData.report_doctype,
            "report_name": reportData.report_name,
            "topic": reportData.topic,
            "note": reportData.note,
            "query": {
                "search": null,
                "jurisdiction": "",
                "area_of_laws": "",
                "topics": "",
            },
            "execute": 1
        })
        setCallChangeReportFilters(() => fetchData)

    }, [reportData])

    const fetchData = async () => {
        setLoading(true);
        var apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report?name=${reportName}`;
        if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
            apiUrl += `&inforce_start_date=${selectedDate.startDate}&inforce_end_date=${selectedDate.endDate}`;
          }
        try {
            const apiKey = Cookies.get('api_key');
            const apiSecret = Cookies.get('api_secret');
            
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });
            if (response.data && response.data.status_code === 200) {
                setReportData(response.data.result);
            } else {
            }
        } catch (error) {
            toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
        }
        setLoading(false);
    };

    return (
        <div className='innerpages-container' style={{position:"relative"}}>
            <Title
                title={reportData.report_name}
                showInfo={false}
                icon={titleImg}
                titleBtn={true}
                onDateChange={handleDateChange}
                onReset={handleResetDate}
                url={url}
                dashboardData={dashboardData}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                reportObject={reportObject}
                callChangeReportFilters={callChangeReportFilters}
                translations={translations}
                language={language}
            />
            <p className='innerpages-para'>
                {reportSection?.data?.page_description ?? ""}
            </p>
            <div className='amendments-info'>
                <div className='hide-date-responsive'>
                    <Text para={translations?.message?.filterByDateRange ? translations.message.filterByDateRange : "Filter by data range:"} />
                    <div className='mt-2'>
                    <DatePickers 
                        inputType={"datetime"} 
                        onDateChange={handleDateChange}
                        onReset={handleResetDate}
                        translations={translations}
                        language={language}
                    />
                    </div>
                </div>
            </div>
            <Results
                url={url} reportID={reportData.name} reportName={reportData.report_name}
                reportNote={reportData.note} reportTopic={reportData.topic}
                regulations={reportData.regulations} filters={reportData.filters} onRefresh={fetchData} loading={loading}
                setLoading={setLoading} searchQuery={searchQuery} translations={translations}
            />
        </div>
    )
}

export default ReportsDetails

import React from 'react'
import "./Covered.css"
import { Title, Count } from '../../../../Components'
import { Col, Container, Row } from 'react-bootstrap'


const Covered = ({coveredSection }) => {

    const { covered_title, coverd_description, employment_law_topics, employee_law_topics_count, area_of_law_detaction,
        area_of_law_detaction_count, industries_covered, industries_covered_count, legal_requirements_included,
        legal_requirements_included_count }
        = coveredSection.data;

    return (
        <div className='covered' data-aos="fade-down" data-aos-duration="2000">
            <Container className='covered-container'>
                <Title title={covered_title} />
                <p className='covered-para'  >{coverd_description}</p>
                <Row className='covered-counts'>
                    <Col lg={3} sm={6}>
                        <Count
                            count={employee_law_topics_count}
                            title={employment_law_topics}
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <Count
                            count={area_of_law_detaction_count}
                            title={area_of_law_detaction}
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <Count
                            count={industries_covered_count}
                            title={industries_covered}
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <Count
                            count={legal_requirements_included_count}
                            title={legal_requirements_included}
                        />
                    </Col>

                </Row>
            </Container>

        </div>
    )
}

export default Covered

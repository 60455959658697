import React from "react";
import "./Hero.css";
import { Container, Row, Col } from "react-bootstrap";
import LeftStar from '../../../../Assets/images/Home/left-star.png';
import { useTheme } from "@emotion/react";


const Hero = ({url, heroSection}) => {

    const { hero_title, hero_sub_title, hero_description, hero_button, hero_img } = heroSection.data;
  

  return (
    <div style={{position:"relative"}}>
        <div className="left-star-div">
            <img src={LeftStar} className="left-star-img "></img>
        </div>
      <Container className="home-hero">
        <Row>
          <Col xl={6} lg={7}>
            <div className="hero-content">
              <h1
                className="hero-title"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                {/* <span>With</span>
                <span>AI</span>
                assistance */}

                <p dangerouslySetInnerHTML={{ __html: hero_title }}></p>
              </h1>
              <h1
                className="hero-second-title"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <p dangerouslySetInnerHTML={{ __html: hero_sub_title }}></p>
              </h1>
              <p
                data-aos="fade-right"
                data-aos-duration="2300"
                className="hero-para"
              >
               {hero_description}
              </p>
              <a href="/bookdemo" className="hero-link">{hero_button}</a>
            </div>
          </Col>
          <Col xl={6} lg={5}>
            <div
              className="hero-image"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
          <img src={url + "/" + hero_img} alt="Hero" />
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;

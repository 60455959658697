import React, { useState } from 'react';
import axios from 'axios';
import "./ResetPassword.css";
import { Container } from 'react-bootstrap';
import blueLock from "../../assets/images/svg/blueLock.svg";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const ResetPassword = ({ url, translations }) => {
    const { email, code } = useParams();
    const decodedEmail = decodeURIComponent(email);
    const decodedCode = decodeURIComponent(code);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for button disabling
    const [loading, setLoading] = useState(false); // New state for loading

    const handleSubmit = async () => {
        if (!decodedEmail || !decodedCode) {
            setError(translations?.message?.invalidResetLink || 'Invalid reset link.');
            return;
        }

        if (password !== confirmPassword) {
            setError(translations?.message?.passwordNotMatch || 'Passwords do not match.');
            return;
        }

        setIsSubmitting(true); // Disable the button when the request is sent
        setLoading(true); // Show loading state

        try {
            const response = await axios.post(`${url}/`, {
                key: decodedCode,
                new_password: password,
                confirm_password: confirmPassword,
                cmd: 'frappe.core.doctype.user.user.update_password',
                email: decodedEmail
            });

            const { authentication, full_name, user_image, sid, preferred_language } = response.data;

            // Store api_key, api_secret, username, full_name, and user_image in cookies
            Cookies.set('api_key', authentication.api_key);
            Cookies.set('api_secret', authentication.api_secret);
            Cookies.set('full_name', full_name);
            Cookies.set('user_image', user_image);
            Cookies.set('sid', sid);
            Cookies.set('preferred_language', preferred_language)

            window.location.href = '/pricing';

        } catch (error) {
            // Check if there's a response from the server
            if (error.response && error.response.data) {
                const serverMessage = error.response.data.exception || error.response.data._server_messages;

                // Clean up the server message to remove 'frappe.exceptions.ValidationError:'
                const cleanedMessage = serverMessage
                    ? serverMessage.replace(/frappe\.exceptions\.ValidationError:\s*/g, '')
                    : '';

                setError(cleanedMessage || (translations?.message?.passwordResetFailed ? translations.message.passwordResetFailed : 'Password reset failed. Please try again.'));
            } else {
                setError(translations?.message?.passwordResetFailed || 'Password reset failed. Please try again.');
            }
        } finally {
            setIsSubmitting(false); // Re-enable the button after the request is complete
            setLoading(false); // Hide loading state
        }
    };

    return (
        <div className='auth'>
            <div className='check-email-container'>
                <Container>
                    <div className='reset-password-image'>
                        <img src={blueLock} alt='Lock' />
                    </div>
                    <h1 className='check-email-title'>
                        {translations?.message?.resetPassword || "Reset Password"}
                    </h1>
                    <p className='check-email-para'>
                        {translations?.message?.pleaseResetPassword || "Please reset your password"}
                    </p>
                    <input
                        type='password'
                        className='login-field password-field'
                        placeholder={translations?.message?.password || "Password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type='password'
                        className='login-field password-field'
                        placeholder={translations?.message?.confirmPassword || "Confirm Password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {error && <p className='error-message'>{error}</p>}
                    <button 
                        className='login-submit' 
                        onClick={handleSubmit} 
                        disabled={isSubmitting} // Disable the button based on isSubmitting state
                    >
                        {loading ? 'Loading...' : 'Continue'} {/* Change button text based on loading state */}
                    </button>
                </Container>
            </div>
        </div>
    );
};

export default ResetPassword;

import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import "./ChartComponent.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ChartComponent = ({ dashboardData, translations }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxAmendments, setMaxAmendments] = useState(0);


  // Check if data is available before calculating maxAmendments

  // Render a message if data is not available
  // if (data.length === 0) {
  //   return <div>No data available to display.</div>;
  // }

  useEffect(() => {
    if (
      dashboardData?.[0] &&
      dashboardData?.[0]?.data &&
      dashboardData?.[0]?.data?.content &&
      dashboardData?.[0]?.data?.content?.yearly_amendment_count &&
      dashboardData?.[0]?.data?.content?.yearly_amendment_count?.objects
    ) {

      setData(
        dashboardData?.[0]?.data?.content?.yearly_amendment_count?.objects || []
      );
      setLoading(false);
    }
  }, [dashboardData]);


  useEffect(() => {
    let maxA =
    dashboardData?.[0]?.data?.content?.yearly_amendment_count?.objects
      .length > 0
      ? Math.max(...data.map((item) => item.num_amendments))
      : 0;
      setMaxAmendments(maxA)

  }, [data]);



  return (
    <>
      {loading ? (
        <>
          <div className="note-container">
            <div className="reminder-skelton">
              <div className="charts-skeleton">
                <SkeletonTheme color="#e6e6e6" highlightColor="#f5f5f5">
                  <Skeleton height={120} width={73} />
                </SkeletonTheme>
              </div>
              <div className="charts-skeleton">
                <SkeletonTheme color="#e6e6e6" highlightColor="#f5f5f5">
                  <Skeleton height={130} width={73} />
                </SkeletonTheme>
              </div>
              <div className="charts-skeleton">
                <SkeletonTheme color="#e6e6e6" highlightColor="#f5f5f5">
                  <Skeleton height={150} width={73} />
                </SkeletonTheme>
              </div>
              <div className="charts-skeleton">
                <SkeletonTheme color="#e6e6e6" highlightColor="#f5f5f5">
                  <Skeleton height={200} width={73} />
                </SkeletonTheme>
              </div>
              <div className="charts-skeleton">
                <SkeletonTheme color="#e6e6e6" highlightColor="#f5f5f5">
                  <Skeleton height={270} width={73} />
                </SkeletonTheme>
              </div>
              <div className="charts-skeleton">
                <SkeletonTheme color="#e6e6e6" highlightColor="#f5f5f5">
                  <Skeleton height={300} width={73} />
                </SkeletonTheme>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <div className="not-found">{translations?.message?.noDataToDisplay ? translations.message.noDataToDisplay : "No data available to display."}</div>
          ) : (
            <ResponsiveContainer
              width="100%"
              height="100%"
              className="responsive-container"
            >
              <BarChart
                data={data}
                margin={{
                  top: 25,
                  right: 40,
                  left: 10,
                  bottom: 10,
                }}
              >
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Bar 
                dataKey="num_amendments"
                // dataKey={translations?.message?.numAmendments ? translations.message.numAmendments : "num_amendments"}
                 fill="#2DB0D8" barSize={73}>
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.num_amendments === maxAmendments
                          ? "#2DB0D8"
                          : "#e6e6e6"
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </>
  );
};

export default ChartComponent;

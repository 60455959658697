import React from 'react'
import "./BookDemo.css"
import { Hero } from '../../Components'
import { Container, Row, Col } from 'react-bootstrap'
import { RegisterForm } from '../../Components/index';


function BookDemo({url, data, translations, language}) {


 

  return (
    <div>
        <Hero 
            mainTitle={"Home /"}
            title={translations?.message?.bookADemo ? translations.message.bookADemo :"Book a demoo"}
            para={translations?.message?.scheduleYourDemo ? translations.message.scheduleYourDemo :"Schedule your demo"}
        />
        <div className='book-demo-container'>
          <Container >
            <Row>
              <Col lg={6}>
                <h1 className='book-demo-title'>{translations?.message?.bookYour ? translations.message.bookYour : "BOOK your "} <span>{translations?.message?.demo ? translations.message.demo : "demo"}</span></h1>
                <p className='book-demo-para'>{translations?.message?.bookDemoDescription ? translations.message.bookDemoDescription : "Book a demo with our team and see how Polaris can modernize your organization. All demos include a free trial!"}</p>
                <img src={require("../../Assets/images/bookdemo/Group 8126.png")} className='book-demo-image' />
              </Col>
              <Col lg={1}></Col>
              <Col lg={5}>
                <RegisterForm url={url} data={data} translations={translations} language={language}> </RegisterForm>
              </Col>
            </Row>
          </Container>
        </div>
       
    </div>
  )
}

export default BookDemo
import React, { useState, useRef, useEffect } from 'react';
import "./Report.css";
import { AddBtn, NotePopUp, Note, ReminderPopUp, AddReport, MenuDots } from '../../Components';
import addBtn from "../../assets/images/svg/addbtn.svg";
import Modal from "react-modal";


const Report = ({ url, property, title, para, reportCount, noteCount, reminderCount, titleLink, onRefresh, noteRegulation,legId, translations, language }) => {

  const [showAddNote, setShowAddNote] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [showAmendmentsBtns, setShowAmendmentsBtns] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showRegulationBtns, setShowRegulationBtns] = useState(false)
  const notePopUpRef = useRef(null);
  const reminderPopUpRef = useRef(null);
  const dateTimePickerRef = useRef(null);
  const showBtnsRef = useRef(null);  // Ref for the `show-btns` container


  const handleAddNoteClick = () => {
    setShowAddNote(prev => !prev);
    setShowAddReminder(false); // Ensure the reminder popup is closed
    setShowPopup(false); // Ensure the report popup is closed
  };

  const handleAddReminderClick = () => {
    setShowAddReminder(prev => !prev);
    setShowAddNote(false); // Ensure the note popup is closed
    setShowPopup(false); // Ensure the report popup is closed
  };

  const togglePopup = () => {
    setShowPopup(prev => !prev);
    setShowAddNote(false); // Ensure the note popup is closed
    setShowAddReminder(false); // Ensure the reminder popup is closed
  };



  const handleNoteClickOutside = (event) => {
    if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
      setShowAddNote(false);
    }
  };

  const handleClickOutsideReminder = (event) => {
    const isClickInsideReminderPopup = reminderPopUpRef.current && reminderPopUpRef.current.contains(event.target);
    const isClickInsideDatePicker = dateTimePickerRef.current && dateTimePickerRef.current.contains(event.target);
    const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);

    if (!isClickInsideReminderPopup && !isClickInsideDatePicker && !isClickInsideDatePickerPopper) {
      setShowAddReminder(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutsideReminder);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideReminder);
    };
  }, []);


  const handleClickOutside = (event) => {
    const isClickInsideDatePickerPopper = document
      .querySelector('[role="dialog"]')
      ?.contains(event.target);
    if (
      showBtnsRef.current &&
      !showBtnsRef.current.contains(event.target) &&
      !isClickInsideDatePickerPopper
    ) {
      setShowRegulationBtns(false);
    }
  };


  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);
  
  useEffect(() => {
    window.addEventListener("mousedown", handleNoteClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleNoteClickOutside);
    };
  }, []);

  const shortType = noteRegulation?.replace("Regulation", "") || "";
  
  return (
    <div className='report'>
      <h1 className='report-property'>{property}</h1>
      <h1 className='regulation-type'>{shortType}</h1>
      <div className='report-title-container'>
        <a href={`${titleLink}`} className='report-title-link'>
          <h1 className='report-title'>{title}</h1>
        </a>
        {isSmallScreen && (
        <div className='show-btns'>
          <MenuDots 
            menuClass={"report-dots"}
            options={[
              <div onClick={togglePopup}>
                <AddBtn
                  name= {translations?.message?.addToReport ? translations.message.addToReport : "Add to Report"}
                  count={reportCount}
                  img={addBtn}
                />
              </div>,
              <div onClick={handleAddNoteClick}>
                <AddBtn
                  name={translations?.message?.addNote ? translations.message.addNote : "Add Note"}
                  count={noteCount}
                  img={addBtn}
                />
              </div>,
              <div onClick={handleAddReminderClick}>
                <AddBtn
                  name={translations?.message?.addReminder ? translations.message.addReminder : "Add Reminder"}
                  count={reminderCount}
                  img={addBtn}
                />
              </div>,
            ]}
          />
        </div>
      )}
      <div className='report-btns'>
        <div ref={notePopUpRef} className={`NotePopUp ${showAddNote ? 'active' : ''}`}>
          <NotePopUp
            url={url}
            count={"4"}
            isOpen={showAddNote}
            onCancel={handleAddNoteClick}
            docName={legId}
            onRefresh={onRefresh}
            translations={translations}
          />
        </div>
        <div ref={reminderPopUpRef} className={`ReminderPopUp ${showAddReminder ? 'active' : ''}`}>
          <ReminderPopUp
            url={url}
            count={"4"}
            docName={legId}
            isOpen={showAddReminder}
            onCancel={handleAddReminderClick}
            onRefresh={onRefresh}
            dateTimePickerRef={dateTimePickerRef}
            translations={translations}
            language={language}
          />
        </div>
        {(!isSmallScreen || showAmendmentsBtns) && (
          <>
            <div onClick={togglePopup}>
              <AddBtn
                  name= {translations?.message?.addToReport ? translations.message.addToReport : "Add to Report"}
                  count={reportCount}
                img={addBtn}
              />
            </div>
            <div onClick={handleAddNoteClick}>
              <AddBtn
                  name={translations?.message?.addNote ? translations.message.addNote : "Add Note"}
                  count={noteCount}
                img={addBtn}
              />
            </div>
            <div onClick={handleAddReminderClick}>
              <AddBtn
                  name={translations?.message?.addReminder ? translations.message.addReminder : "Add Reminder"}
                  count={reminderCount}
                img={addBtn}
              />
            </div>
          </>
        )}
      </div>
    
      </div>
      <p className='report-para'>{para}</p>
      <Modal isOpen={showPopup} onRequestClose={togglePopup}>
        <AddReport
          cancelBtn={togglePopup}
          url={url}
          regulation={legId}
          translations={translations}
        />
      </Modal>
    </div>
  );
}

export default Report;

import React, { useState, useEffect } from 'react';
import "./Regulation.css";
import { Title, Text, DatePickers, SelectFilter, UnderDevelopment } from '../../Components';
import { Results } from '../../Features/Regulation';
import icon from "../../assets/images/svg/amendments.svg";
import Modal from 'react-modal';
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Regulation = ({ url , dashboardData,selectedFilters, setSelectedFilters,searchQuery,setSearchQuery,callChangeReportFilters, reportObject,loading, translations, language }) => {
  const location = useLocation();
  const [isClear, setIsClear] = useState(false);
  const regulationSection = dashboardData?.find(
    (section) => section.section === "Regulation List"
  );
  const { query } = useParams();
  const searchParams = useSearchParams();
  const query1 = new URLSearchParams(searchParams.toString()).get("query");
  const [showPopup, setShowPopup] = useState(false);
  // const searchParams1 = new URLSearchParams(window.location.search);
  // const searchQuery1 = searchParams1.get("query");
  // const email = location.state && location.state.searchQuery;
  // const { from } = location.state??""
  // const searchQueryFromStorage = localStorage.getItem('regulationSearchQuery') || '';

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };



  // useEffect(() => {
  //   if(searchQueryFromStorage){
  //     setSearchQuery(searchQueryFromStorage);
  //   }
  // }, [searchQueryFromStorage]);

  // useEffect(() => {
  //   if(email){
  //     setSearchQuery(email);
  //   }
  // }, [email]);

  const handleResetDate = () => {
    setSelectedDate(null); // Reset the selected date
    setIsClear(true);
  };

  const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });

  const handleDateChange = (date) => {
      if (!date || !date.startDate || !date.endDate) {
          // Handle the case where date or startDate or endDate is null
          return;
   }
    
      // Function to format date as "Y-%m-%d %H:%M:%S"
      const formatDate = (inputDate) => {
        if (!inputDate) return null;
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const day = inputDate.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
      };
    
      // Set time to midnight for the start date and format it
      const startDateTime = date.startDate ? formatDate(new Date(date.startDate)) : null;
    
      // Set time to midnight for the end date and format it
      const endDateTime = date.endDate ? formatDate(new Date(date.endDate)) : null;
    
      setSelectedDate({
        startDate: startDateTime,
        endDate: endDateTime
      });
    };
  
  useEffect(() => {
    }, [selectedDate]);

  return (
    <div className="innerpages-container">
      <Title
        title={translations?.message?.regulations ? translations.message.regulations : "Regulations"} 
        showInfo={false}
        icon={icon}
        titleBtn={true}
        onDateChange={handleDateChange}
        onReset={handleResetDate}
        url={url}
        dashboardData={dashboardData}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        reportObject={reportObject}
        callChangeReportFilters={callChangeReportFilters}
        loading={loading}
        translations={translations}
        language={language}
      />
      <p className="innerpages-para" dangerouslySetInnerHTML={{ __html:  regulationSection?.data?.page_description??""}}>
      </p>
      <div className="amendments-info">
        <div className="hide-responsive">
          <Text para={translations?.message?.filterByDateRange ? translations.message.filterByDateRange : "Filter by data range:"} />
          <div className="mt-2">
            <DatePickers
              inputType={"datetime"}
              onDateChange={handleDateChange}
              onReset={handleResetDate}
              translations={translations}
              language={language}
            />
          </div>
        </div>
        {/* <div className='hide-responsive'>
          <Text para={"Filter by jurisdictions"} />
          <div className='mt-2' onClick={togglePopup}>
            <SelectFilter  />
          </div>
        </div> */}
      </div>
      <Modal
        isOpen={showPopup}
        onRequestClose={togglePopup}
        contentLabel="Select Filter Popup"
      >
        <UnderDevelopment btnClick={togglePopup} translations={translations} />
      </Modal>
      <Results
        url={url}
        selectedFilters={selectedFilters}
        selectedDate={selectedDate}
        searchQuery={searchQuery}
        dashboardData={dashboardData}
        clearedDate={isClear}
        translations={translations}
        language={language}
      />
    </div>
  );
}

export default Regulation;
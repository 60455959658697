import React, { useState, forwardRef } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './DateTimePickers.css';
// import dayjs from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import French locale for Day.js
import 'dayjs/locale/en'; // You can also import English if needed

const DateTimePickerValue = forwardRef(({ handleDateTimeChange, value, language } , ref) => {
  return (
    //ref={ref}
    <div ref={ref}>
      
      <div className="date-time-picker">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language === 'fr' ? 'fr' : 'en'} // Set the locale dynamically based on the language
        >          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              value={value}
              onChange={handleDateTimeChange}
              PopperProps={{
                id: 'date-time-picker-popper', // Add a unique ID to the popper
                disablePortal: false, // Ensure it can be detected in the DOM
              }}
              // label={language === 'fr' ? 'Sélectionner la date et l\'heure' : 'Select Date and Time'} // Translate the label dynamically

            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </div>
  );
});

export default DateTimePickerValue;
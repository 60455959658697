import React from 'react'
import "./Contact.css"
import { ContactUs, Hero } from "../../Components"
import { Container } from 'react-bootstrap';

const Contact = ({url, data, translations, language}) => {
  const contactSection = data?.find(item => item.section === "Contact Us Section");

  return (
    <div className='contact-page'>
      <Hero
        mainTitle={"Home /"}
        title={translations?.message?.contactUs ? translations.message.contactUs :"Contact us"}
        para={translations?.message?.contactUs ? translations.message.contactUs :"Contact us"}
      />
      <Container>
        <ContactUs url={url} contactSection={contactSection} translations={translations} language={language}/>
      </Container>
    </div>
  )
}

export default Contact
import React, { useState, useEffect } from 'react'
import "./AddReport.css"
import { Row, Col } from "react-bootstrap"
import notes from "../../assets/images/svg/notes.svg"
import arrow from "../../assets/images/svg/arrowRightBlack.svg"
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from 'axios';
import { ConfirmAdd } from '../../Components';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'validator'


const AddReport = ({ url, cancelBtn, topic, note, start, limit, selectedFilters, inforce_start_date, inforce_end_date, regulation, showAddNew, is_empty = false, onRefresh, translations }) => {

    const [reportName, setReportName] = useState("");
    const [reportExistingName, setReportExistingName] = useState("");
    const [reportExistingObject, setReportExistingObject] = useState({});
    const [areaOfLaws, setAreaOfLaws] = useState("");
    const [topics, setTopics] = useState("");
    const [jurisdiction, setJurisdiction] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([]);
    let pageNumber = 0;
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [showLoad, setShowLoad] = useState(true);
    const [AddNew, setAddNew] = useState(false);


    useEffect(() => {

        if (selectedFilters && selectedFilters.area_of_laws) {
            setAreaOfLaws(selectedFilters.area_of_laws.join(","));
        }
        if (selectedFilters && selectedFilters.topics) {
            setTopics(selectedFilters.topics.join(","));
        }
        if (selectedFilters && selectedFilters.jurisdiction) {
            setJurisdiction(selectedFilters.jurisdiction.join(","));
        }
    }, [selectedFilters]);

    useEffect(() => {
        if (currentPage != totalPages) {
            setShowLoad(true);
        }
        else {
            setShowLoad(false);
        }
    }, [currentPage, totalPages]);

    useEffect(() => {
        setCurrentPage(1);
        setTotalPages(1);
        pageNumber = 0; // Reset page number
        fetchData();
    }, [searchText]);

    const fetchData = async (clear = true) => {
        setLoading(true);
        try {
            const apiKey = Cookies.get("api_key");
            const apiSecret = Cookies.get("api_secret");
            let apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report?start=${pageNumber}&limit=20`;
            if (searchText) {
                apiUrl += `&search=${searchText}`
            }
            const response = await axios.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });

            if (response.data.status_code !== 200) {
                toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData : "Failed to fetch data. Please try again..");
            }

            const result = response.data.result;
            setCurrentPage(result.pagination.metadata.current_page);
            setTotalPages(result.pagination.metadata.total_pages);
            setData(prevData => clear ? [...result.objects] : [...prevData, ...result.objects]);

        } catch (error) {
            toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData : "Failed to fetch data. Please try again..");
        }
        setLoading(false);
    };

    const onPageChange = (pageNumb) => {
        pageNumber = (pageNumb) * 20;
        fetchData(false);
    };



    const confirmAdd = async () => {
        const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report`;
        const data = {
            "report_doctype": "Legislation",
            "report_name": reportName,
            "topic": "",
            "note": "",
            "query": is_empty == true || regulation ? {} : {
                "jurisdiction": jurisdiction,
                "area_of_laws": areaOfLaws,
                "topics": topics,
                "countries": null,
                "inforce_start_date": inforce_start_date,
                "inforce_end_date": inforce_end_date,
                "start": start,
                "limit": limit
            },
            "is_empty": is_empty ? 1 : 0
        };
        if (regulation) {
            data["regulation"] = regulation;
        }
        try {
            const apiKey = Cookies.get('api_key');
            const apiSecret = Cookies.get('api_secret');
            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });
            if (response.data && response.data.status_code === 200) {
                toast.success(translations?.message?.reportAddedSuccessfuly ? translations.message.reportAddedSuccessfuly : 'Report added successfully.');
                if (onRefresh)
                    onRefresh(true);
            } else {
                toast.error(translations?.message?.failedAddReport ? translations.message.failedAddReport : 'Failed to add the report.');
                if (onRefresh)
                    onRefresh(false);
            }
        } catch (error) {
            if (onRefresh)
                onRefresh(false);
            toast.error(translations?.message?.failedAddReport ? translations.message.failedAddReport : 'Failed to add the report.');
        }

        setShowPopup(false);

        cancelBtn();
    };
    const cancelAdd = () => {
        setShowPopup(false);
    };


    const confirmAddToAnExistingReport = async () => {
        if (!regulation) {
            setShowPopup(false);
            cancelBtn();
            return;
        }
        const updatedQuery = {};
        reportExistingObject.filters.forEach(filter => {
            updatedQuery[filter.field] = filter.value;
        });

        const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report`;
        const data = {
            "name": reportExistingObject.name,
            "report_name": reportExistingName,
            "topic": reportExistingObject.topic,
            "note": reportExistingObject.note,
            "add": regulation,
        };

        try {
            const apiKey = Cookies.get('api_key');
            const apiSecret = Cookies.get('api_secret');
            const response = await axios.put(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });
            if (response.data && response.data.status_code === 200) {
                toast.success(translations?.message?.regulationAddedSuccessfuly ? translations.message.regulationAddedSuccessfuly : 'Regulation added successfully.');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || translations?.message?.failedAddRegulation ? translations.message.failedAddRegulation : 'Failed to add the regulation.');
        }
        setShowPopup(false);
        cancelBtn();
    };


    const handleAdd = () => {
        setAddNew(true);
        if (reportName.trim() === '') {
            setErrorMessage(translations?.message?.pleaseAddReportName ? translations.message.pleaseAddReportName : 'Please Add a Report Name');
            setShowPopup(false);
        }
        else {
            setShowPopup(true);
        }
    };

    const handleAddExisting = () => {
        setAddNew(false);
        if (reportExistingName.trim() === '') {
            setErrorMessage(translations?.message?.pleaseSelectReportName ? translations.message.pleaseSelectReportName : 'Please Select a Report Name');
            setShowPopup(false);
        }
        else {
            setShowPopup(true);
        }
    };

    useEffect(() => {
    }, [reportExistingName]);

    return (
        <div className='add-report-container'>
            {/* {showAddNew && <> */}
            <div className='add-report-header'>
                <div className='d-flex align-items-center'>
                    <img className='add-report-img' src={notes} />
                    <h1 className='add-report-title'>

                        {translations?.message?.createNewReport ? translations.message.createNewReport : "Create new report"}
                    </h1>
                </div>
                <div className='d-flex align-items-center'>
                    <a href="/dashboard/reports" className='see-all-reports-link'>
                        <h1 className='see-all-reports'>
                            {translations?.message?.seeAllReports ? translations.message.seeAllReports : "See All Reports"}
                        </h1>
                        <img src={arrow} />
                    </a>
                </div>
            </div>

            <div className='add-report-inputs'>
                <input type='text' className='add-report-text' placeholder={translations?.message?.yourReportName ? translations.message.yourReportName : "Your report name"} onChange={(event) => setReportName(event.target.value)} />

                <button className='add-report-button' onClick={() => handleAdd()}>
                    {translations?.message?.add ? translations.message.add : "ADD"}
                </button>
            </div>
            {AddNew && errorMessage && (
                <div className="popup-error">
                    <p>{errorMessage}</p>
                </div>
            )}

            {/* </>} */}

            {!showAddNew &&
                <>
                    <div className='add-report-break'>
                        <Row>
                            <Col sm={5} className="add-report-line"></Col>
                            <Col sm={2}>

                                {translations?.message?.or ? translations.message.or : "OR"}

                            </Col>
                            <Col sm={5} className="add-report-line"></Col>
                        </Row>
                    </div>
                    {/* <h1 className='add-report-existing'>Add to an Existing Report</h1> */}

                    <div className='add-report-header'>
                        {/* <div className='d-flex align-items-center'>
                            <img className='add-report-img' src={notes} />
                            <h1 className='add-report-title'>Add to an Existing Report</h1>
                        </div> */}
                        <h1 className='add-report-existing'>

                            {translations?.message?.addToExistReport ? translations.message.addToExistReport : "Add to an Existing Report"}
                        </h1>

                        <div className='d-flex align-items-center'>
                            {/* <h1 className='add-report-existing'>Add to an Existing Report</h1> */}
                            <a href="/dashboard/reports" className='see-all-reports-link'>
                                <h1 className='see-all-reports'>
                                    {translations?.message?.seeAllReports ? translations.message.seeAllReports : "See All Reports"}
                                </h1>
                                <img src={arrow} />
                            </a>
                        </div>
                    </div>

                    {/* <div style={{ marginTop: '15px' }}> */}
                    <input type='text' className='add-report-text' placeholder={translations?.message?.searchExistReport ? translations.message.searchExistReport : "Search for an existing report"} onChange={(event) => { setSearchText(event.target.value); }} />
                    {/* </div> */}
                    {!AddNew && errorMessage && (
                        <div className="popup-error">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                    <div>
                        {loading ? (
                            <div className='progress-loading'>
                                <CircularProgress size={25} />
                            </div>
                        ) :
                            (
                                < div className='add-report-checks' >
                                    {data.length == 0 ?
                                        <div class="not-found">{translations?.message?.noReports ? translations.message.noReports : "There are no reports."}</div> :
                                        (<>

                                            {data.map((item, index) => (
                                                <div className='add-report-content'>
                                                    <input type='radio' name='report' className='add-report-checkbox' onChange={(event) => {
                                                        if (event.target.value == "on") {
                                                            setReportExistingName(item.report_name);
                                                            setReportExistingObject(item)
                                                        }
                                                        else {
                                                            setReportExistingName("");
                                                            setReportExistingObject({});
                                                        }
                                                    }} /><label className='add-report-label'>{item.report_name}</label>
                                                </div>
                                            ))}

                                            {showLoad == true ?
                                                <button className='load-more' onClick={() => onPageChange(currentPage)}>{translations?.message?.loadMore ? translations.message.loadMore + ".." : "Load more.."}</button> :
                                                <div></div>
                                            }
                                        </>
                                        )
                                    }
                                </div>
                            )}

                    </div>

                </>
            }

            <div className='add-report-btns'>
                <button className='add-report-cancle' onClick={cancelBtn}>

                    {translations?.message?.cancel ? translations.message.cancel : "Cancle"}
                </button>
                {!showAddNew && <button className='add-report-save' onClick={() => handleAddExisting()}>
                    {translations?.message?.save ? translations.message.save : "Save"}
                </button>}
            </div>
            <Modal
                isOpen={showPopup}
                onRequestClose={cancelAdd}
                contentLabel="Confirmation Popup"
            >
                <ConfirmAdd
                    para={translations?.message?.areSureAdd ? translations.message.areSureAdd : "Are you sure you want to add this?"}
                    cancleAdd={cancelAdd}
                    confirmAdd={AddNew ? confirmAdd : confirmAddToAnExistingReport}
                    addPara={translations?.message?.yesAdd ? translations.message.yesAdd : "Yes, Add"}
                    translation={translations}
                />
            </Modal>
        </div>
    )
}

export default AddReport
import React from 'react';
import "./SideBtn.css";

const SideBtn = ({ svgImage, title, isActive }) => {
    const sideBtnClasses = isActive ? 'sidebtn active-sidebtn' : 'sidebtn';

    return (
        <div className={sideBtnClasses}>
            <div className='sidebtn-image'>
                {
                    svgImage
                }
            </div>
            <h1 className='sidebtn-title'>{title}</h1>
        </div>
    )
}

export default SideBtn;
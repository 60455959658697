import React from 'react'
import "./PlanCart.css"
import { Container, Row, Col } from "react-bootstrap"
import CheckIcon from '../../../../Assets/images/svg/Check.svg'; 
import { useNavigate } from 'react-router-dom';

const PlanCart = ({ isPro, price, type, attr ,translations, mainType }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/payment?planType=${mainType}`);
    };

    return (
        <Container >
            <div  className={`cart ${isPro ? 'cart-pro' : ''}`}>

                 
                <div>
                    <div  className='plan-type'>{type}</div>
                    {isPro !== 0 && <div className='btn-recomended'> {translations?.message?.recommended ? translations.message.recommended : "Recommended"}</div>}
                    <div className='cart-text'> {translations?.message?.chooseOurMultiple ? translations.message.chooseOurMultiple : "Choose our Multiple"}</div>
                    <div className='plan-price'>{price}</div>
                </div>
                <div>
                    {
                        attr.map((item, index)=> (
                            <div className='feature'>
                                <img src={CheckIcon} alt="CheckIcon" className='check-icon'/>
                                <div className='cart-text'>{item.attribute_value} {item.attribute}</div>
                            </div>
                        ))
                    }
                  
                    {/* <div className='feature'>
                    <img src={CheckIcon} alt="CheckIcon" className='check-icon'/>
                        <div className='cart-text'>Up to 5 users</div>
                    </div>
                    <div className='feature'>
                    <img src={CheckIcon} alt="CheckIcon" className='check-icon'/>
                        <div className='cart-text'>Add-on: up to 10 users</div>
                    </div>
                    <div className='feature'>
                    <img src={CheckIcon} alt="CheckIcon" className='check-icon'/>
                        <div className='cart-text'>Email support</div>
                    </div> */}
                    
                </div>
                <div>
                    <button  className={`btn-plan ${isPro ? 'btn-plan-pro' : ''}`} onClick={handleClick}>{translations?.message?.getStarted ? translations.message.getStarted :"Get Started"}</button>
                </div>
            </div>

        </Container>
    )
}

export default PlanCart

import React, { useState, useEffect } from 'react';
import "./Pricing.css";
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { PlanCart } from "../../Features";
import { ContactUs } from '../../Components';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { toast } from "react-toastify";


const Pricing = ({ url, data, translations, language }) => {
  const [pricingData, setPricingData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [isYearly, setIsYearly] = useState(false);  

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const discoverSection = data?.find(item => item.section === "Discover Plans Section");
  const contactSection = data?.find(item => item.section === "Contact Us Section");
  let discover_title = "", discover_discription = "";
  if (discoverSection) {
    ({
      discover_title, discover_discription
    } = discoverSection.data || {});
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/method/erpnext.accounts.doctype.subscription_plan.subscription_plan.subscription_plan`);
        setPricingData(response.data.result);
        setMonthData(response.data.result.Month || []);
        setYearData(response.data.result.Year || []);
        setLoading(false);
      } catch (error) {
        toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      boxSizing: 'border-box',
    },
  }));

  const handleSwitchChange = (event) => {
    setIsYearly(event.target.checked);
  };

  const renderPlanCarts = (data) => {
    return data.map((item, index) => {
   
      return (
        <Col lg={4} md={6} key={index} className='plan-cart-container' data-aos='zoom-in' data-aos-duration="1500">
          <PlanCart
            price={item.formatted_price}
            mainType={item.id}
            type={item.name}
            attr={item.attributes}
            isPro={item.is_suggested_plan}
            translations={translations}
          />
        </Col>
      );
    });
  };

  return (
    <div className='pricing-page'>
      <Container>
        <div className='plan-div'>
          <div className='plan-title' data-aos='fade-down' data-aos-duration="1500">{discover_title}</div>
          <div className='plan-text' data-aos='fade-down' data-aos-duration="1800">{discover_discription}</div>
          <div className='plan-date-div'>
            <h1 className='plan-date'>{translations?.message?.monthly ? translations.message.monthly : "Monthly"}</h1>
            <Form.Check
              type="switch"
              checked={isYearly}
              onChange={handleSwitchChange}
            />
            <h1 className='plan-date'>{translations?.message?.yearly ? translations.message.yearly :"Yearly"}</h1>
          </div>
        </div>
        <Row className='row-plan-cart justify-content-center'>
          {isYearly ? (
            Array.isArray(yearData) && yearData.length > 0 ? (
              renderPlanCarts(yearData)
            ) : (
              <div className='text-center'>{translations?.message?.noYearlyPlanAvailable ? translations.message.noYearlyPlanAvailable :"No yearly plans available"}</div>
            )
          ) : (
            Array.isArray(monthData) && monthData.length > 0 ? (
              renderPlanCarts(monthData)
            ) : (
              <div className='text-center'>{translations?.message?.noMonthlyPlanAvailable ? translations.message.noMonthlyPlanAvailable :"No monthly plans available"}</div>
            )
          )}
        </Row>
        <ContactUs url={url} contactSection={contactSection} translations={translations} language={language}></ContactUs>
      </Container>
    </div>
  );
};

export default Pricing;

import React from 'react'
import "./Lawyers.css"
import { Container, Col, Row } from 'react-bootstrap'
import { LinkBtn, Title } from '../../../../Components'

const Lawyers = ({url , lawyersSection}) => {

    const { lawyers_img, lawyers_title_part1,lawyers_title_part2, lawyers_description, lawyers_btn, lawyers_btn1 } = lawyersSection.data;


  return (
    <Container className='lawyers'>
        <Row className='lawyers-container'>
            <Col lg={7}>
                <div className='lawyers-content'>
                    <Title title={lawyers_title_part1} />
                    <Title 
                        title={lawyers_title_part2}
                        color={"var(--main-color)"} 
                    />
                    <p className='lawyers-para' data-aos="fade-down" data-aos-duration="1500">{lawyers_description}</p>
                    <div className='lawyers-links' data-aos="fade-down" data-aos-duration="1500">
                        <LinkBtn 
                            to={"/bookdemo"}
                            color={"#fff"} 
                            bg={"var(--main-color)"}
                            title={lawyers_btn}
                        />

                        {/* <LinkBtn 
                            color={"var(--main-color)"} 
                            bg={"#fff"}
                            title={lawyers_btn1}
                        /> */}
                    </div>
                </div>
            </Col>
            <Col lg={5}>
                <div className='lawyers-image' data-aos='fade-down' data-aos-duration="1500" >
                    <img src={(url+ "/"+ lawyers_img)} />
                </div>
                
            </Col>
        </Row>
    </Container>
  )
}

export default Lawyers
import React, { useState } from 'react';
import './Login.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import google from '../../assets/images/svg/google.svg';

const Login = ({ url, translations, language }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    setUsernameError('');
    setPasswordError('');
    setLoginError('');

    if (!username.trim()) {
      setUsernameError(translations?.message?.usernameRequired ? translations.message.usernameRequired : 'Username is required');
      return;
    }

    if (!password.trim()) {
      setPasswordError(translations?.message?.passwordRequired ? translations.message.passwordRequired : 'Password is required');
      return;
    }

    try {
      setIsLoading(true);

      const response = await axios.post(`${url}/api/method/login`, {
        usr: username,
        pwd: password,
      },
        {
          withCredentials: true // Make sure cookies are sent and received
        }
      );

      if (response.status !== 200) {
        throw new Error(translations?.message?.loginFailed ? translations.message.loginFailed : 'Login failed');
      }

      const { authentication, full_name, user_image, sid, preferred_language } = response.data;

      // Store api_key, api_secret, username, full_name, and user_image in cookies
      Cookies.set('api_key', authentication.api_key);
      Cookies.set('api_secret', authentication.api_secret);
      Cookies.set('username', username);
      Cookies.set('full_name', full_name);
      Cookies.set('user_image', user_image);
      Cookies.set('sid', sid);
      Cookies.set('preferred_language', preferred_language); 
      if(preferred_language != language){
        localStorage.clear();
      }
      // Redirect to the homepage
      window.location.href = '/dashboard';
    } catch (error) {
      setLoginError(translations?.message?.wrongUserNameOrPassword ? translations.message.wrongUserNameOrPassword : 'Wrong username or password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth">
      <div className="login-container">
        <Container>
          <h1 className="login-title">{translations?.message?.login ? translations.message.login : "Log in"} </h1>
          <p className="login-para">
          {translations?.message?.loginDescription ? translations.message.loginDescription : "dolor sit amet, consectetur adipiscing elit. Vestibulum nisl leo consectetur."}
          </p>
          <form className="login-fields" onSubmit={handleLogin}>
            <input
              type="text"
              className={`login-field user-field ${usernameError && 'error'}`}
              placeholder={translations?.message?.username ? translations.message.username : "Username"}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {usernameError && <p className="error-message">{usernameError}</p>}
            <input
              type="password"
              className={`login-field password-field ${passwordError && 'error'}`}
              placeholder={translations?.message?.password ? translations.message.password : "Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
            {loginError && <p className="error-message">{loginError}</p>}
            {/* <div className="forget">
               <a className="forget-link" href="#">
                Forgot your password?
              </a>
              <div className="remember">
                <input type="checkbox" />
                <label className="remember-label">Remember me</label>
              </div> 
            </div> */}
            <div className="login-btn">
              <input
                type="submit"
                value= {translations?.message?.login ? translations.message.login : "Log in"}
                className={`login-submit ${isLoading ? 'disabled' : ''}`}
                disabled={isLoading}
              />
              {/* <a href="#" className="sign-google">
                <img src={google} alt="Google" />
                Sign in with Google
              </a> */}
            </div>
          </form>
          <div className="no-account">
            <p>
              {translations?.message?.dontHaveAccount ? translations.message.dontHaveAccount : "Don't have an account?"}
            </p>
            <a href={'/register'}>
              <a>
                {translations?.message?.signUpNow ? translations.message.signUpNow : "Sign up now"}</a>
            </a>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import "./RegulationDetails.css";
import { Text, Btn, SpinnerLoading } from '../../Components';
import { Detail } from '../../Features/Regulation';
import addBtn from "../../assets/images/svg/addbtn.svg";
import compare from "../../assets/images/svg/compare.svg";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import axios from "axios";

const RegulationDetails = ({ url, dashboardData, translations }) => {
  const [data, setData] = useState(null); // Changed to null to allow proper checks
  const [loading, setLoading] = useState(false);
  const [regType, setRegType] = useState(false);


  let regulationData;
  dashboardData.map((item) => (
    regulationData = item?.data?.content?.regulations
  ));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const currentPath = window.location.pathname;
      const currentSearch = window.location.search;

      const name = currentPath.split("/").pop();
      const regulationType = decodeURIComponent(currentSearch.substring(1));
      setRegType(regulationType);

      let apiUrl = `${url}/api/method/projectx.projectx.doctype.federal_regulation.federal_regulation.get_regulation_details?name=${name}`;
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      setLoading(true);

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });

      if (response.data.status_code !== 200) {
        toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Using fallback.");
        setData(null);  // Use null to indicate failure so the fallback can trigger
        setLoading(false);
        return;
      }

      const result = response.data.result;
      setData(result); // Successfully got the data from API
      setLoading(false);
    } catch (error) {
      toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Using fallback.");
      setData(null); // Set to null to trigger the fallback
      setLoading(false);
    }
  };

  const [showAllAreas, setShowAllAreas] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 476);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 476);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (data) {
      document.title = `Polaris | ${data.title}`;
    }
  }, [data]);

  useEffect(() => {
    if (regulationData) {
      document.title = `Polaris | ${regulationData?.title}`;
    }
  }, [regulationData]);

  // Use API data if available, otherwise fall back to regulationData
  const displayData = data ? data : regulationData;

  if (!displayData) {
    return (
      <div className='detail-loading'>
        <SpinnerLoading />
      </div>
    );
  }

  const areasOfLaws = displayData?.areas_of_laws
    ? displayData.areas_of_laws.split(',').map(area => area.trim())
    : [];

  const displayedAreas = showAllAreas || !isSmallScreen
    ? areasOfLaws
    : areasOfLaws.slice(0, 4);

  const resourcesData = displayData?.resources ? JSON.parse(displayData.resources) : [];
  const amendmentNum = displayData?.amendments ? displayData.amendments.length : "";

  return (
    <div className='innerpages-container'>
      <div className='regulation-container'>
        <div className='regulation-header'>
        <div className='d-flex align-items-center gap-2' style={{ flexWrap: "wrap" }}>
            {displayedAreas.map((area, index) => (
              <div className='pe-none' key={index}>
                <Btn bg={"#2db0d824"} name={area} color={"#2DB0D8"} />
              </div>
            ))}
            {isSmallScreen && areasOfLaws.length > 4 && (
              <div>
                <button 
                  className="btn-show-more" 
                  onClick={() => setShowAllAreas(prev => !prev)}
                >
                  {showAllAreas ? "Show Less" : "Show More"}
                </button>
              </div>
            )}
          </div>
          <h1 className='regulation-title'>{displayData.short_title}</h1>
        </div>
        <Detail 

          url={url} 
          namebtn1={"Compare with a specific version"}
          namebtn2={"Compare Jurisdictions"}
          namebtn3={translations?.message?.addToReport ? translations.message.addToReport : "Add to Report"}

          imgbtn1={<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.57895 0C1.15684 0 0 1.1775 0 2.625C0 3.81 0.781053 4.80225 1.84211 5.12775V10.4977C1.84137 10.632 1.862 11.8335 2.70716 12.696C3.22884 13.23 3.92737 13.5 4.7821 13.5V15L7.72947 12.75L4.7821 10.5V12C3.43884 12 3.32021 10.8495 3.31579 10.5V5.12775C4.37684 4.80225 5.15789 3.81 5.15789 2.625C5.15789 1.1775 4.00032 0 2.57895 0ZM2.57895 3.75C1.96958 3.75 1.47368 3.24525 1.47368 2.625C1.47368 2.00475 1.96958 1.5 2.57895 1.5C3.18832 1.5 3.68421 2.00475 3.68421 2.625C3.68421 3.24525 3.18832 3.75 2.57895 3.75ZM12.1579 9.87225V4.49775C12.1542 3.29325 11.3643 1.5 9.20316 1.5V0L6.25579 2.25L9.20316 4.5V3C10.5508 3 10.6776 4.15425 10.6842 4.5V9.87225C9.62316 10.1978 8.8421 11.19 8.8421 12.375C8.8421 13.8225 9.99895 15 11.4211 15C12.8432 15 14 13.8225 14 12.375C14 11.19 13.2189 10.1978 12.1579 9.87225ZM11.4211 13.5C10.8117 13.5 10.3158 12.9952 10.3158 12.375C10.3158 11.7548 10.8117 11.25 11.4211 11.25C12.0304 11.25 12.5263 11.7548 12.5263 12.375C12.5263 12.9952 12.0304 13.5 11.4211 13.5Z" fill="#2DB0D8"/>
            </svg>
            }
          imgbtn2={<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.57895 0C1.15684 0 0 1.1775 0 2.625C0 3.81 0.781053 4.80225 1.84211 5.12775V10.4977C1.84137 10.632 1.862 11.8335 2.70716 12.696C3.22884 13.23 3.92737 13.5 4.7821 13.5V15L7.72947 12.75L4.7821 10.5V12C3.43884 12 3.32021 10.8495 3.31579 10.5V5.12775C4.37684 4.80225 5.15789 3.81 5.15789 2.625C5.15789 1.1775 4.00032 0 2.57895 0ZM2.57895 3.75C1.96958 3.75 1.47368 3.24525 1.47368 2.625C1.47368 2.00475 1.96958 1.5 2.57895 1.5C3.18832 1.5 3.68421 2.00475 3.68421 2.625C3.68421 3.24525 3.18832 3.75 2.57895 3.75ZM12.1579 9.87225V4.49775C12.1542 3.29325 11.3643 1.5 9.20316 1.5V0L6.25579 2.25L9.20316 4.5V3C10.5508 3 10.6776 4.15425 10.6842 4.5V9.87225C9.62316 10.1978 8.8421 11.19 8.8421 12.375C8.8421 13.8225 9.99895 15 11.4211 15C12.8432 15 14 13.8225 14 12.375C14 11.19 13.2189 10.1978 12.1579 9.87225ZM11.4211 13.5C10.8117 13.5 10.3158 12.9952 10.3158 12.375C10.3158 11.7548 10.8117 11.25 11.4211 11.25C12.0304 11.25 12.5263 11.7548 12.5263 12.375C12.5263 12.9952 12.0304 13.5 11.4211 13.5Z" fill="#2DB0D8"/>
            </svg>
            }
          imgbtn3={addBtn}
          mainTitle={"Canada"}
          date={"11/4/2007 7:00"}
          showFirstAlert={resourcesData.length > 0 ? true : false} 
          showSecondAlert={amendmentNum > 0 ? true : false}
          xmlFilName={data.file_path} 
          regulationName={displayData.name}
          amendmentNum={amendmentNum}
          resourcesData={resourcesData}
          regulationType={regType}
          legId={displayData.id}
          translations={translations}
        />
      </div>
    </div>
  );
};

export default RegulationDetails;

import React, { useState } from 'react';
import axios from 'axios';
import "./Register.css";
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { RegisterForm } from '../../../Website/Components/index';


const Register = ({ url, data, translations, language }) => {
    const registerSection = data?.find(item => item.section === "Register Form");

    const { industry_types = [], company_sizes = [] } = (registerSection && registerSection.data && registerSection.data.content) || {};


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        organization: "",
        interest: "",
        industry: "",
        companySize: "",
        note: "",
        profilePicture: null,
        profilePictureBase64: "",
        profilePictureName: ""
    });

    const [registrationMessage, setRegistrationMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture' && files.length > 0) {
            const file = files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePicture: file,
                profilePictureName: file.name
            }));
            convertFileToBase64(file);
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePictureBase64: reader.result
            }));
        };
    };

    const handlePhoneChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            phoneNumber: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.company`;

        const data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            company_size: formData.companySize,
            company_name: formData.organization,
            location: formData.note,
            email: formData.email,
            phone_number: formData.phoneNumber,
            logo: {
                filename: formData.profilePictureName,
                data: formData.profilePictureBase64
            }
        };


        try {
            const apiKey = Cookies.get('api_key');
            const apiSecret = Cookies.get('api_secret');
            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });


            if (response.data && response.data.status_code === 200) {
                setRegistrationMessage(response.data.message);
                navigate('/checkemail', { state: { email: formData.email } });
            } else {
                console.error('Unexpected response structure:', response.data);
                setRegistrationMessage(translations?.message?.unExpectedResponseStructure ? translations.message.unExpectedResponseStructure : 'Unexpected response structure.');
            }
        } catch (error) {
            setRegistrationMessage(translations?.message?.registrationFailedPleaseTryAgain ? translations.message.registrationFailedPleaseTryAgain : 'Registration failed. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div >
            <Container>
                <div className="new-register">
                    <h1 className='login-title'>
                        {translations?.message?.register ? translations.message.register : "Register"}
                    </h1>
                    <RegisterForm url={url} data={data} translations={translations} language={language}></RegisterForm>
                </div>
                {registrationMessage && <p>{registrationMessage}</p>}
            </Container>
        </div>
    );
}

export default Register;
